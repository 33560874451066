import { observer } from 'mobx-react'
import React, { useState, ChangeEvent } from 'react'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import { Trash, CheckCircleFill, CircleHalf } from 'react-bootstrap-icons';


import styles from '../../../styles/Home.module.css'

import { IBudget } from '../../../core/entities/Budget';
import { IBudgetItem } from '../../../core/entities/BudgetItem'

import { useStores } from '../../stores/rootStore'


function BudgetCardItemHeader({item}: {item: IBudgetItem}) {
  const rootStore = useStores();
  const demoNumbersMode = rootStore.demoNumbersMode;

  const amount = demoNumbersMode ? item.amount / 10 : item.amount;
  const amountPaid = demoNumbersMode ? item.amountPaid / 10 : item.amountPaid;

  if (item.isPartialPaid) {
    return (<>{item.name} - {amountPaid}/{amount}</>)
  }

  const title = `${item.name} - ${amount}`;

  if (item.paid) {
    return <del>{title}</del>
  }

  return (<>{title}</>)
}

type Props = {
  budget: IBudget,
  item: IBudgetItem,
}

function BudgetCardItem({budget, item}: Props) {
  const { budgetsStore } = useStores();
  const [partialAmount, changeAmount] = useState(100);
  const [showPartialInput, setShowPartialInput] = useState(false);

  const deleteItem = (event: React.MouseEvent): void => {
    event.preventDefault();
    event.stopPropagation();
    budgetsStore.deleteItem(budget.id, item.id);
  }

  const markItemAsPaid = (event: React.MouseEvent): void => {
    event.preventDefault();
    event.stopPropagation();
    budgetsStore.markItemAsPaid(budget.id, item);
  }

  const handleChangeAmount = (event: ChangeEvent<HTMLInputElement>): void => {
    changeAmount(parseInt(event.target.value))
  }

  const handlePartialInput = () => {
    if (!showPartialInput) {
      setShowPartialInput(true);
    } else {
      budgetsStore.markItemAsPaid(budget.id, item, partialAmount);
      setShowPartialInput(false);
    }
    
  }

  return (
    <div className={styles.cardItem}>
        <BudgetCardItemHeader item={item} />{' '}
        <div className={styles.cardItemActions}>
          <Trash className="text-danger icon-action" onClick={deleteItem} />
          <ButtonGroup className="ml-1" aria-label="Budget item actions">
            {!item.paid &&  <Button variant="outline-success" size="sm" onClick={markItemAsPaid}><CheckCircleFill /></Button>}
            {showPartialInput &&  <input name="amount"  type="number" value={partialAmount} onChange={handleChangeAmount} />}
            {!item.paid &&  <Button variant="outline-success" size="sm" onClick={handlePartialInput}><CircleHalf /></Button>}
          </ButtonGroup>
        </div>
    </div>
  );
}

export default observer(BudgetCardItem);