import { v4 as uuidv4 } from 'uuid';

import { ReactElement, useState, useMemo, ChangeEvent } from 'react'
import { observer } from "mobx-react";

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Dropdown from 'react-bootstrap/Dropdown';
import { Row, Col } from 'react-bootstrap';
import { Trash, ThreeDotsVertical } from 'react-bootstrap-icons';

import BudgetsStore from '../../stores/budgetsStore'
import Income, { IIncome } from '../../../core/entities/Income';
import FullIncome from '../../../core/entities/FullIncome';
import IncomeAllocation, { IIncomeAllocation } from '../../../core/entities/IncomeAllocation';
import Transaction, {TransactionDraft, TransactionTypes} from '../../../core/entities/Transaction';
import TransactionsModalTable from '../transactionsModalTable';


type CreateIncomeModalProps = {
  budgetsStore: BudgetsStore,
  show: boolean,
  handleClose: () => void,
  income: FullIncome | undefined
}


export default observer(function({budgetsStore, show, handleClose, income}: CreateIncomeModalProps): ReactElement {
  const [model, setIncome] = useState<IIncome>({
  	id: income ? income.id : uuidv4(),
  	createdAt: income ? income.createdAt : new Date(),
	  name: income ? income.name : '',
	  notes: income ? income.notes : '',
	  sources: income ? income.sources : [
      {name: '', amount: null, createdAt: new Date(), note: null},
    ],
	});  

  const [showNotes, setShowNotes] = useState(!!income?.notes);
  const [showConfirm, setShowConfirm] = useState(false);


  // fill allocations by income id
	const [allocations, setAllocations] = useState<IIncomeAllocation[]>(
    income ? income.allocations :
  [
		{id: '', createdAt: new Date(), amount: undefined, budgetId: '', incomeId: model.id, note: null},
	]
  );

  const [expences, setExpences] = useState<TransactionDraft[]>([]);

  const handleAddAllocation = () => {
    setAllocations([...allocations, {id: '', createdAt: new Date(), amount: undefined, budgetId: '', incomeId: model.id, note: ''}])
  }

  const handleChangeName = (event: ChangeEvent<HTMLInputElement>): void => {
    setIncome({...model, name: event.target.value})
  }

  const handleChangeNotes = (event: ChangeEvent<HTMLInputElement>): void => {
    setIncome({...model, notes: event.target.value})
  }

  const handleChangeSourceName = (index:number, event: ChangeEvent<any>): void => {
    model.sources[index].name = event.target.value;
    setIncome({...model});
  }

  const handleChangeSourceAmount = (index:number, event: ChangeEvent<any>): void => {
    model.sources[index].amount = parseInt(event.target.value);
    setIncome({...model});
  }

  const handleDeleteSource = (index: number) => {
    model.sources.splice(index, 1);
    setIncome({...model});
  }

  const handleAddSource = () => {
    model.sources.push({name: '', amount: null, createdAt: new Date(), note: null});
    setIncome({...model});
  }

  // handle change source note
  const handleChangeSourceNote = (index:number, event: ChangeEvent<any>): void => {
    if (event.target.value === '') {
      model.sources[index].note = null;
      setIncome({...model});
      return;
    }
    model.sources[index].note = event.target.value;
    setIncome({...model});
  }

  const handleAddSourceNote = (index: number) => {
    model.sources[index].note = '';
    setIncome({...model});
  }

  const handleChangeAmountAllocation = (index:number, event: ChangeEvent<any>): void => {
    allocations[index].amount = parseInt(event.target.value);
    setAllocations([...allocations]);
  }

  const handleDeleteAllocation = (index: number) => {
    const newAllocations = [...allocations];
    newAllocations.splice(index, 1);
    setAllocations(newAllocations);
  }

  const handleAddAllocationNote = (index: number) => {
    allocations[index].note = '';
    setAllocations([...allocations]);
  }

  const handleChangeAllocationNote = (index:number, event: ChangeEvent<any>): void => {
    if (event.target.value === '') {
      allocations[index].note = null;
      setAllocations([...allocations]);
      return;
    }
    allocations[index].note = event.target.value;
    setAllocations([...allocations]);
  }

  const handleSetAllocationBudgetId = (index:number, event: ChangeEvent<any>): void => {
    allocations[index].budgetId = event.target.value;
    setAllocations([...allocations]);
  }

  const handleAddAllocationToLatestIncome = (index: number) => {
    const allocation = new IncomeAllocation({
      id: uuidv4(),
      amount: allocations[index].amount,
      incomeId: '',
      budgetId: allocations[index].budgetId,
      createdAt: new Date(),
    });
    budgetsStore.addIncomeAllocationToLatestIncome(allocation);
  }

  const isValid = () => {
    if (!model.name && !model.sources.some((item) => item.amount)) {
      return false;
    }

    return true;
  }

  const handleSave = (event: any) => {
    if (!isValid()) {
      return;
    }

    const obj = new Income(model)

    if (income) {
    	budgetsStore.updateIncome(obj);
    } else {
      budgetsStore.createIncome(obj);
    }

    const allocationToDelete = income?.allocations.map((item) => item.id) || [];

    allocations.forEach((allocation) => {
      if (allocation.id) {
          allocationToDelete.splice(allocationToDelete.indexOf(allocation.id), 1);
        	budgetsStore.updateIncomeAllocation(allocation as IncomeAllocation);
      } else {
        if (!allocation.amount) {
          return;
        }

        const obj = new IncomeAllocation({
          id: uuidv4(),
          amount: allocation.amount,
          incomeId: allocation.incomeId,
          budgetId: allocation.budgetId,
          createdAt: allocation.createdAt,
        });        
        budgetsStore.createIncomeAllocation(obj);
      }
    })

    allocationToDelete.forEach((id) => budgetsStore.deleteIncomeAllocation(id));

    handleSaveExpences();

  	handleClose();
  };

  const handleDelete = () => {
    if (showConfirm) {
      if (income) {
        budgetsStore.deleteIncome(income.id);
      }
      setShowConfirm(false);
      handleClose();
    } else {
      setShowConfirm(true);
    }
  };

  // Direct expences

  const handleRemoveTransaction = (tid: string) => {
    budgetsStore.deleteTransaction(tid);
  }

  const handleEditTransaction = (transaction: Transaction) => {
    budgetsStore.openEditTransactionModal(transaction);
  }

  const handleAddExpence = (type: TransactionTypes) => {
    setExpences([...expences, {amount: undefined, name: undefined,  type: type, incomeId: income?.id}])
  }

  const handleChangeExpenceName = (index: number, event: ChangeEvent<any>): void => {
    const newExpences = [...expences];
    newExpences[index].name = event.target.value;
    setExpences(newExpences);
    isValid();
  }

  const handleChangeExpenceAmount = (index: number, event: ChangeEvent<any>): void => {
    const newExpences = [...expences];
    newExpences[index].amount = parseInt(event.target.value);
    setExpences(newExpences);
    isValid();
  }

  const handleRemoveExpence = (index: number) => {
    const newExpences = [...expences];
    newExpences.splice(index, 1);
    setExpences(newExpences);
  }

  const handleSaveExpences = () => {
    expences.forEach(expence => {
      if (!expence.amount) {
        return;
      }
      
      const newTransaction = new Transaction({
        id: uuidv4(),
        name: expence.name || '',
        amount: expence.amount,
        type: expence.type,
        createdAt: new Date(),
        incomeId: expence.incomeId,
      });
      budgetsStore.addTransaction(newTransaction.id, newTransaction);
    })
    setExpences([]);
  }

  var obj: Income;

  if (!income) {
    obj = new Income(model);
  } else {
    obj = income;
  }  

  const totalAllocated = useMemo(() => {
    return allocations.reduce((total, item) => total + (item.amount || 0), 0);
  }, [allocations]);

  const totalExpences = income?.totalExpences || 0;

  const freeIncomeAmount = obj.amount - totalAllocated - totalExpences;

  return (
  	<Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{income ? model.name : "New income group"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>

          <Form.Group as={Row} className="mb-3" controlId="formBasicEmail">
            <Form.Label column sm={3}>Name</Form.Label>
            <Col sm={9}>
              <Form.Control required={!model.name} type="text" placeholder="Enter name" onChange={handleChangeName} value={model.name} />
            </Col>
          </Form.Group>

          {income && (
            <Row className="mb-1">
              <Col>Created date: &nbsp;&nbsp;&nbsp;&nbsp;{income.createdAt.toLocaleString()}</Col>
            </Row>
          )}

          <Row className="mb-1">
            <Col xs={3}>Total:</Col>
            <Col xs={3}>{obj.amount}</Col>
          </Row>

          <Row className="mb-1">
            <Col xs={3}>Allocated:</Col>
            <Col xs={3}>{totalAllocated}</Col>
          </Row>

          <Row className="mb-1">
            <Col xs={3}>Expences:</Col>
            <Col xs={3}>{totalExpences}</Col>
          </Row>

          <Row className="mb-3">
            <Col xs={3}>Free:</Col>
            <Col xs={3}>{freeIncomeAmount}</Col>
          </Row>

          <h4>Income sources</h4>
          {model.sources.map((source, index) => (
            <Form.Group className="mb-2" key={index}>
              
              <Row>
                <Dropdown drop='start'>
                <InputGroup>
                  <InputGroup.Text id="basic-addon1">{index + 1}</InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="Enter name"
                      onChange={(event) => handleChangeSourceName(index, event)}
                      value={source.name || ''}
                    />
                    <Form.Control
                      type="number"
                      placeholder="Enter amount"
                      onChange={(event) => handleChangeSourceAmount(index, event)}
                      value={source.amount || ''}
                    />
                    <Dropdown.Toggle className="dots-menu" id="dropdown-basic" variant="outline-secondary" size="sm">
                      <ThreeDotsVertical size={18} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => handleDeleteSource(index)}>
                        Delete
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => handleAddSourceNote(index)}>
                        Add note
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.ItemText>
                        {source.createdAt?.toLocaleString()}
                      </Dropdown.ItemText>
                    </Dropdown.Menu>
                </InputGroup>
                {source.note !== null && (
                  <div className='mt-1 mx-1'>
                    <Form.Control
                      type="text"
                      size='sm'
                      placeholder="Enter note"
                      onChange={(event) => handleChangeSourceNote(index, event)}
                      value={source.note || ''}
                    />
                  </div>
                )}
                </Dropdown>
              </Row>
            </Form.Group>
          ))}

          <Button variant="outline-primary" size="sm" onClick={handleAddSource}>Add source</Button>

          <h4 className='mt-3'>Allocations</h4>
          {allocations.map((allocation, index) => (
            <Form.Group className="mb-2" key={index}>
              
              <Row>
                <Dropdown drop='start'>
                <InputGroup>
                  <InputGroup.Text id="basic-addon1">{index + 1}</InputGroup.Text>
                    <Form.Control
                      type="number"
                      placeholder="Enter amount"
                      onChange={(event) => handleChangeAmountAllocation(index, event)}
                      value={allocation.amount || ''}
                    />
                  <Form.Select value={allocation.budgetId} onChange={(event) => handleSetAllocationBudgetId(index, event)}>
                    <option>-</option>
                    {budgetsStore.budgets.map(budget => {
                      return <option key={budget.id} value={budget.id}>{budget.name}</option>
                    })}
                  </Form.Select>
                    <Dropdown.Toggle className="dots-menu" id="dropdown-basic" variant="outline-secondary" size="sm">
                      <ThreeDotsVertical size={18} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => handleDeleteAllocation(index)}>
                        Delete
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item onClick={() => handleAddAllocationNote(index)}>
                        Add note
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => handleAddAllocationToLatestIncome(index)}>
                        Duplicate to latest income
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.ItemText>
                        {allocation.createdAt?.toLocaleString()}
                      </Dropdown.ItemText>
                    </Dropdown.Menu>
                </InputGroup>
                {allocation.note !== null && (
                  <div className='mt-1 mx-1'>
                    <Form.Control
                      type="text"
                      size='sm'
                      placeholder="Enter note"
                      onChange={(event) => handleChangeAllocationNote(index, event)}
                      value={allocation.note || ''}
                    />
                  </div>
                )}
                </Dropdown>
              </Row>
            </Form.Group>
          ))}

          <Button variant="outline-primary" size="sm" onClick={handleAddAllocation}>
            Add allocation
          </Button>

          {(income?.transactions.length || null) && (
          <>
            <h3 className='mt-4'>Direct expences</h3>
            <TransactionsModalTable
              transactions={income!.transactions}
              showAll={true}
              handleEditTransaction={handleEditTransaction}
              handleRemoveTransaction={handleRemoveTransaction}
              handleTransactionToPaid={() => {}}
            />
          </>
          )}

          <Row>
            <Col>
              {expences.map((expence, index) => {
                return (
                  <Form.Group className="my-2" key={index}>
                    <InputGroup>
                      {/* <InputGroup.Text>Planned</InputGroup.Text> */}
                      <Form.Control autoFocus type="number" placeholder="amount" value={expence.amount || ''} onChange={(event) => handleChangeExpenceAmount(index, event)} />
                      <Form.Control name="name" autoComplete='off' placeholder="name" type="text" value={expence.name || ''} onChange={(event) => handleChangeExpenceName(index, event)} />
                      <Button variant="outline-danger" size="sm" onClick={() => handleRemoveExpence(index)}><Trash /></Button>
                    </InputGroup>
                  </Form.Group>
                )
              })}
          
              <Button className="mt-2" size="sm" variant="outline-primary" onClick={() => handleAddExpence(1)}>
                Add direct expence
              </Button>
            </Col>
          </Row>

          <Row>
            <Col>
              {(model?.notes?.length ||  showNotes || null) && (
                <Form.Group className="my-3" controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Notes:</Form.Label>
                  <Form.Control
                    as="textarea"
                    className='font-monospace'
                    rows={5}
                    value     = {model.notes || ''}
                    onChange  = {handleChangeNotes}
                  />
                </Form.Group>
              )}
            </Col>
          </Row>
          
          {((!model.notes?.length && !showNotes) || null) && (
            <Button className="mt-2" size="sm" variant="outline-primary" onClick={() => setShowNotes(true)}>
              Add notes
            </Button>
          )}

        </Form>
      </Modal.Body>
      <Modal.Footer>
        {income && (
          <Button variant={showConfirm ? "danger" : "warning"} className="me-auto float-left" onClick={handleDelete}>
            {showConfirm ? "Confirm delete?" : "Delete"}
          </Button>
        )}
        <Button variant="secondary" className="mr-auto" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
})