import { initializeApp } from "firebase/app"
import { getFunctions, connectFunctionsEmulator, httpsCallable } from 'firebase/functions';

import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

import {
  collection, getFirestore,
  DocumentData, CollectionReference
} from "firebase/firestore";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
    databaseURL: `https://${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseio.com`,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.appspot.com`,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

const firebaseApp = initializeApp(firebaseConfig);

export const firebaseAuth = getAuth();

const googleProvider = new GoogleAuthProvider();

export function signInWithGoogle() {
  return signInWithPopup(firebaseAuth, googleProvider)
}

const db = getFirestore(firebaseApp);

export const functions = getFunctions(firebaseApp);

if (process.env.NODE_ENV === 'development') {
  connectFunctionsEmulator(functions, 'localhost', 5001);
}

export const getAISuggestionsForSpeech = httpsCallable(
  functions,
  'getAISuggestionsForSpeech'
);

export const getAISuggestion = httpsCallable(
  functions,
  'getAISuggestion'
);


export const createCollection = <T = DocumentData>(collectionName: string) => {
  return collection(db, collectionName) as CollectionReference<T>
}
