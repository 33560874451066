import { v4 as uuidv4 } from 'uuid';

import { observer } from "mobx-react";
import { ReactElement, useState, ChangeEvent, useEffect } from 'react'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { Row, Col } from 'react-bootstrap';

import Note from '../../../core/entities/Note';
import NotesStore from '../../stores/notesStore';


type ModalProps = {
  notesStore: NotesStore
  note: Note | null,
  show: boolean,
  handleClose: () => void,
}

export default observer(function({notesStore, show, handleClose, note}: ModalProps): ReactElement {
  const spaces = 4;
  const [title, setTitle] = useState(note? note.title : "");

  const [text, setText] = useState<{value: string, caret: number, target: any}>({value: note? note.text : "", caret: -1, target: null});

  useEffect(() => {

      if(text.caret >= 0){

          text.target.setSelectionRange(text.caret + spaces, text.caret + spaces);

      }

  }, [text]);

  const handleTab = (e: any) => {

      let content = e.target.value;
      let caret   = e.target.selectionStart;

      if(e.key === 'Tab'){

          e.preventDefault();

          let newText = content.substring(0, caret) + ' '.repeat(spaces) + content.substring(caret);

          setText({value: newText, caret: caret, target: e.target});

      }

  }

  const [showConfirm, setShowConfirm] = useState(false);

  const handleChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  }

  const handleChangeText = (e: ChangeEvent<HTMLInputElement>) => {
    setText({value: e.target.value, caret: -1, target: e.target});
  }

  const handleSave = () => {
    if (note) {
      note.title = title;
      note.text = text.value;
      notesStore.updateNote(note);
      handleClose();
      return;
    }

    const newNote = new Note({
      id: uuidv4(),
      title: title,
      text: text.value,
      createdAt: new Date(),
      order: notesStore.notes.length + 1,
    });
    notesStore.addNote(newNote);
    handleClose();
  }

  const handleDelete = () => {
    if (showConfirm) {
      if (note) {
        notesStore.deleteNote(note.id);
      }
      setShowConfirm(false);
      handleClose();
    } else {
      setShowConfirm(true);
    }
  }

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{note? 'Note: ' + title : 'New Note'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>

          <Form.Group as={Row} className="mb-3" controlId="formBasicEmail">
            <Form.Label column sm={2}>Title:</Form.Label>
            <Col sm={10}>
              <Form.Control type="text" placeholder="Enter note title" onChange={handleChangeTitle} value={title} />
            </Col>
          </Form.Group>
          
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Text:</Form.Label>
            <Form.Control
              as="textarea"
              className='font-monospace'
              rows={10}
              onKeyDown = {handleTab}
              value     = {text.value}
              onChange  = {handleChangeText}
            />
          </Form.Group>

        </Form>
      </Modal.Body>
      <Modal.Footer>
        {note && (
          <Button variant={showConfirm ? "danger" : "warning"} className="me-auto float-left" onClick={handleDelete}>
            {showConfirm ? "Confirm delete?" : "Delete"}
          </Button>
        )}
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
})