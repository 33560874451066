import { v4 as uuidv4 } from 'uuid';

import { ReactElement, useState, ChangeEvent } from 'react'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'

import BudgetsStore from '../../stores/budgetsStore'
import Budget from '../../../core/entities/Budget';


type CreateBudgetModalProps = {
  budgetsStore: BudgetsStore,
  show: boolean,
  handleClose: () => void,
  budget: Budget | undefined
}

export default function CreateBudgetModal({budgetsStore, show, handleClose, budget}: CreateBudgetModalProps): ReactElement {
  const initialName = budget ? budget.name : 'New budget';
  const initialAmount = budget ? budget.amount : 10000;
  const initialArchived = budget ? budget.isArchived : false;
  const initialCountInTotal = budget ? budget.countInTotal : false;

  const initialSourceBudget = budgetsStore.budgets.find(
    obj => obj.id === budget?.source?.budgetId
  );

  const initialSourceBudgetItem = initialSourceBudget?.items?.find(
    obj => obj.id === budget?.source?.budgetItemId
  );

  const [name, setName] = useState(initialName);
  const [amount, setAmount] = useState(initialAmount);
  const [archived, setArchived] = useState(initialArchived);
  const [countInTotal, setCountInTotal] = useState(initialCountInTotal);
  const [useSource, setUseSource] = useState(!!budget?.source?.budgetId);
  const [sourceBudget, setSourceBudget] = useState(initialSourceBudget);
  const [sourceBudgetItem, setSourceBudgetItem] = useState(initialSourceBudgetItem);

  const handleChangeName = (event: ChangeEvent<HTMLInputElement>): void => {
    setName(event.target.value)
  }

  const handleChangeAmount = (event: ChangeEvent<HTMLInputElement>): void => {
    setAmount(parseInt(event.target.value))
  }

  const handleChangeArchived = (event: ChangeEvent<HTMLInputElement>): void => {
    setArchived(event.target.checked)
  }

  const handleChangeTotal = (event: ChangeEvent<HTMLInputElement>): void => {
    setCountInTotal(event.target.checked)
  }

  const handleUseSource = (event: ChangeEvent<HTMLInputElement>): void => {
    setUseSource(event.target.checked)
  }

  const handleChangeSourceBudget = (event: ChangeEvent<HTMLInputElement>): void => {
    const budget = budgetsStore.budgets.find(
      obj => obj.id === event.target.value
    );
    setSourceBudget(budget);
  }

  const handleChangeSourceBudgetItem = (event: ChangeEvent<HTMLInputElement>): void => {
    const item = sourceBudget?.items?.find(
      obj => obj.id === event.target.value
    );
    setSourceBudgetItem(item);
  }

  const handleSave = () => {
    const isCreate = !budget;

    let obj: Budget;

    if (budget) {
      budget.name = name;
      budget.amount = amount;
      budget.isArchived = archived;
      budget.countInTotal = countInTotal;
      obj = budget;
    } else {
      obj = new Budget({
        id:             uuidv4(),
        name:           name,
        createdAt:      undefined,
        collapsed:      false,
        countInTotal:   countInTotal,
        isArchived:     archived,
        source:         null,
        amount:         amount,
        items:          [],
        notes:          '',
      })
    }


    if (useSource && sourceBudget && sourceBudgetItem) {
      obj.source = {
        budgetId: sourceBudget.id,
        budgetItemId: sourceBudgetItem.id
      }
    }

    if (isCreate) {
      budgetsStore.createBudget(obj);
    } else {
      budgetsStore.updateBudget(obj);
    }

    handleClose();
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{initialName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>

          <Form.Group controlId="formBasicEmail">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" placeholder="Enter email" onChange={handleChangeName} value={name} />
          </Form.Group>

          <Form.Group controlId="formBasicAmount">
            <Form.Label>Amount</Form.Label>
            <Form.Control type="number" placeholder="Enter Amount" onChange={handleChangeAmount} value={amount} />
          </Form.Group>

          <Form.Check 
            type='switch'
            id='switch-source'
            label={`Source budget`}
            onChange={handleUseSource} checked={useSource}
          />

          {useSource && 
          <div>
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label>Source budget</Form.Label>
              <Form.Control as="select" value={sourceBudget?.id} onChange={handleChangeSourceBudget}>
                <option>-</option>
                {budgetsStore.budgets.map(budget => {
                  return <option key={budget.id} value={budget.id}>{budget.name}</option>
                })}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="exampleForm.ControlSelect2">
              <Form.Label>Source budget item</Form.Label>
              <Form.Control as="select" value={sourceBudgetItem?.id} onChange={handleChangeSourceBudgetItem}>
                <option>-</option>
                {sourceBudget?.items?.map(item => {
                  return <option key={item.id} value={item.id}>{item.name}</option>
                })}
              </Form.Control>
            </Form.Group>
          </div>
          }

          <Form.Check 
            type='switch'
            id='checkbox-budget'
            label={`is archived`}
            onChange={handleChangeArchived} checked={archived}
          />

          <Form.Check 
            type='switch'
            id='checkbox-total'
            label='count in total'
            onChange={handleChangeTotal} checked={countInTotal}
          />

        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}