import Moment from 'moment';

import { ReactElement, useMemo } from 'react'

import Transaction from '../../core/entities/Transaction';


type ComponentProps = {
  transactions: Transaction[]
}

export default function TotalTransactions({transactions}: ComponentProps): ReactElement {
  const agg_transactions: any = useMemo(
    () => {
      const agg_transactions: { [index: string]: number } = {};
      
      transactions.forEach(transaction => {
        const date = Moment(transaction.createdAt!).format('DD-MM-YYYY');
        if (!agg_transactions.hasOwnProperty(date)) {
          agg_transactions[date] = 0
        }
        agg_transactions[date] += transaction.amount;
        
      });
      return agg_transactions
    },
    [transactions]
  );

  return (
    <div className='mt-2'>
      <h4>Total by dates</h4>
      {agg_transactions && Object.entries(agg_transactions).map(([key, value]) => (
        <div key={key}>{key} {Math.ceil(value as number)}</div>
      ))}
    </div>
  )

}