import { createContext, useContext } from "react"
import { reaction } from 'mobx'

import AuthStore from './authStore';
import budgetsStore from './budgetsStore';
import notesStore from './notesStore';


export class RootStore {
  authStore: AuthStore;
  budgetsStore: budgetsStore;
  notesStore: notesStore;
  demoNumbersMode = false;

  constructor() {
    this.authStore = new AuthStore();
    this.budgetsStore = new budgetsStore(this);
    this.notesStore = new notesStore(this);

    reaction(
      () => this.authStore.userId,
      () => this.budgetsStore.fetchBudgets()
    )
  }
}

export const rootStore = new RootStore()
export const StoreContext = createContext(rootStore)
export const useStores = (): RootStore => useContext(StoreContext)