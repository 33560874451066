import React, { useState, useRef, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'

import { observer } from 'mobx-react'
import { ArrowsCollapse, PencilSquare, Trash } from 'react-bootstrap-icons';

import styles from '../../../styles/Home.module.css'
import BudgetCardItem from './budgetCardItem'
import BudgetItemForm from './budgetItemForm'

import { useStores } from '../../stores/rootStore'
import Budget from '../../../core/entities/Budget'

type BudgetCardTitleProps = {
  budget: Budget,
  demoNumbersMode: boolean,
}

type Props = {
  budget: Budget,
}

function BudgetCardTitle({budget, demoNumbersMode}: BudgetCardTitleProps) {
  let amounts;
  
  if (demoNumbersMode) {
    amounts = `${budget.safeAmount / 10}/${budget.plannedUnpaidAmount / 10}/${budget.free / 10}`;
  } else {
    amounts = `${budget.safeAmount}/${budget.plannedUnpaidAmount}/${budget.free}`;
  }

  return <h3>{budget.name} - {amounts}</h3>
}

function BudgetCard({budget}: Props) {
  const rootStore = useStores();
  const budgetsStore = rootStore.budgetsStore;
  const [ isEditMode, setEditMode ] = useState(false);

  const budgetCardRef = useRef<HTMLDivElement>(null);

  const sourceBudget = budgetsStore.budgets.find(
    obj => obj.id === budget.source?.budgetId
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [budgetCardRef]);

  const collapse = (): void => {
    budgetsStore.collapseBudget(budget);
  }

  const edit = (): void => {
    budgetsStore.openBudgetModal(budget);
  }

  const deleteBudget = (): void => {
    budgetsStore.deleteBudget(budget.id);
  }

  const refreshBudget = (): void => {
    budgetsStore.refreshBudget(budget);
  }

  const handleSetEditMode = () => {
    setEditMode(true);
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (budgetCardRef.current && !budgetCardRef.current.contains(event.target as Node)) {
      setEditMode(false);
    } 
  }

  return (
    <div className={styles.card} ref={budgetCardRef}>
      <div className={styles.cardContent}>
        <BudgetCardTitle budget={budget} demoNumbersMode={rootStore.demoNumbersMode} />
        {sourceBudget && <p>linked to -&gt; {sourceBudget.name}</p>}
        
        <ButtonGroup aria-label="Budget actions" className="mb-1">
          <Button variant="outline-secondary" size="sm" onClick={collapse}><ArrowsCollapse /></Button>
          <Button variant="outline-secondary" size="sm" onClick={edit}><PencilSquare /></Button>
          <Button variant="outline-danger" size="sm" onClick={deleteBudget}><Trash /></Button>
          <Button variant="outline-danger" size="sm" onClick={refreshBudget}>R</Button>
        </ButtonGroup>

        {!budget.collapsed && budget.safeItems.map(item => (
          <BudgetCardItem budget={budget} item={item} key={item.id} />
        ))}

        {!budget.safeItems.length && <p>- no items -</p>}
      </div> 
      <BudgetItemForm budget={budget} isEditMode={isEditMode} setEditMode={handleSetEditMode} />

    </div>
  )
}

export default observer(BudgetCard);