import { v4 as uuidv4 } from 'uuid';

import { observer } from "mobx-react";
import { ReactElement, useState, ChangeEvent } from 'react'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { Row, Col } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';

import BudgetsStore from '../../stores/budgetsStore'
import FullBudget from '../../../core/entities/FullBudget';
import Budget from '../../../core/entities/Budget';
import Transaction, {TransactionDraft, TransactionTypes} from '../../../core/entities/Transaction';
import styles from './budgetModalFull.module.css';
import TransactionsModalTable from '../transactionsModalTable';


type CreateBudgetModalProps = {
  budgetsStore: BudgetsStore,
  show: boolean,
  handleClose: () => void,
  budget: FullBudget | undefined
}

function BudgetSpentProperties({budget}: {budget: FullBudget}) {
  return (
    <>
    <Row className="mb-1">
      <Col xs={5} sm={4}>Spent 7d: </Col>
      <Col xs={7} sm={8}>{budget.spent7d}</Col>
    </Row>

    <Row className="mb-1">
      <Col xs={5} sm={4}>Spent 30d: </Col>
      <Col xs={7} sm={8}>{budget.spent30d}</Col>
    </Row>

    <Row className="mb-1">
      <Col xs={5} sm={4}>Allocated 30d: </Col>
      <Col xs={7} sm={8}>{budget.allocated30d}</Col>
    </Row>

    <Row className="mb-1">
      <Col xs={5} sm={4}>Allocated YTD: </Col>
      <Col xs={7} sm={8}>{budget.allocatedYTD}</Col>
    </Row>
    </>
  );
}


export default observer(function({budgetsStore, show, handleClose, budget}: CreateBudgetModalProps): ReactElement {
  const initialName = budget ? budget.name : '';
  const initialCountInTotal = budget ? budget.countInTotal : true;
  const initialArchived = budget ? budget.isArchived : false;
  const initialNotes = budget ? budget.notes : '';

  const [name, setName] = useState(initialName);
  const [archived, setArchived] = useState(initialArchived);
  const [countInTotal, setCountInTotal] = useState(initialCountInTotal);
  const [notes, setNotes] = useState(initialNotes);
  const [showNotes, setShowNotes] = useState(!!notes);

  const [expences, setExpences] = useState<TransactionDraft[]>([]);

  // ui control states
  const [showConfirm, setShowConfirm] = useState(false);
  const [showExtraStats, setShowExtraStats] = useState(false);
  const [showMoreIncome, setShowMoreIncome] = useState(false);

  const handleChangeName = (event: ChangeEvent<HTMLInputElement>): void => {
    setName(event.target.value)
  }

  const handleChangeArchived = (event: ChangeEvent<HTMLInputElement>): void => {
    setArchived(event.target.checked)
  }

  const handleChangeTotal = (event: ChangeEvent<HTMLInputElement>): void => {
    setCountInTotal(event.target.checked)
  }

  const handleChangeNotes = (event: ChangeEvent<HTMLInputElement>): void => {
    setNotes(event.target.value)
  }

  const isValid = (): boolean => {
    return !!name;
  }

  const handleSave = () => {
    if (!isValid()) {
      return;
    }
    const isCreate = !budget;

    let obj: Budget;

    if (budget) {
      obj = new Budget({
        id:             budget.id,
        name:           name,
        createdAt:      budget.createdAt,
        collapsed:      false,
        countInTotal:   countInTotal,
        isArchived:     archived,
        notes:          notes,
      })
    } else {
      obj = new Budget({
        id:             uuidv4(),
        name:           name,
        createdAt:      undefined,
        collapsed:      false,
        countInTotal:   countInTotal,
        isArchived:     archived,
        notes:          notes,
      })
    }

    if (isCreate) {
      budgetsStore.createBudget(obj);
      budgetsStore.openFullBudgetModal(obj.id);
      // set budgetId for expences
      expences.forEach((e) => {
        e.budgetId = obj.id;
      });
      handleSaveExpences();
    } else {
      budgetsStore.updateBudget(obj);
      handleSaveExpences();
      handleClose();
    }
  }

  const handleDelete = () => {
    if (showConfirm) {
      if (budget) {
        budgetsStore.deleteBudget(budget.id);
      }
      setShowConfirm(false);
      handleClose();
    } else {
      setShowConfirm(true);
    }
  }

  const handleTransactionToPaid = (transaction: Transaction) => {    
    transaction.switchToPaid();
    budgetsStore.updateTransaction(transaction.id, transaction);
  }
  
  const handleRemoveTransaction = (tid: string) => {
    budgetsStore.deleteTransaction(tid);
  }

  const handleEditTransaction = (transaction: Transaction) => {
    budgetsStore.openEditTransactionModal(transaction);
  }

  const handleAddExpence = (type: TransactionTypes) => {
    setExpences([...expences, {amount: undefined, name: undefined,  type: type, budgetId: budget?.id}])
  }

  const handleChangeExpenceName = (index: number, event: ChangeEvent<any>): void => {
    const newExpences = [...expences];
    newExpences[index].name = event.target.value;
    setExpences(newExpences);
    isValid();
  }

  const handleChangeExpenceAmount = (index: number, event: ChangeEvent<any>): void => {
    const newExpences = [...expences];
    newExpences[index].amount = parseInt(event.target.value);
    setExpences(newExpences);
    isValid();
  }

  const handleRemoveExpence = (index: number) => {
    const newExpences = [...expences];
    newExpences.splice(index, 1);
    setExpences(newExpences);
  }

  const handleSaveExpences = () => {
    expences.forEach(expence => {
      if (!expence.amount) {
        return;
      }
      
      const newTransaction = new Transaction({
        id: uuidv4(),
        name: expence.name || '',
        amount: expence.amount,
        type: expence.type,
        budgetId: expence.budgetId,
      })
      budgetsStore.addTransaction(newTransaction.id, newTransaction);
    })
    setExpences([]);
  }

  function getAllocations() {
    if (showMoreIncome)  {
      return budget?.allocations
    }
    return budget?.allocations?.slice(0, 3);
  }

  return (
    <Modal show={show} onHide={handleClose} size={budget ? 'lg' : undefined}>
      <Modal.Header closeButton>
        <Modal.Title>{budget? 'Budget: ' + initialName : 'New budget'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>

          <Form.Group as={Row} className="mb-3" controlId="formBasicEmail">
            <Form.Label column sm={3}>Name</Form.Label>
            <Col sm={9}>
              <Form.Control type="text" placeholder="Enter budget name" onChange={handleChangeName} value={name} />
            </Col>
          </Form.Group>
          
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>Options</Form.Label>
            <Col sm={9} className="py-1">
              <Form.Check 
                required
                type='switch'
                id='checkbox-total'
                label='count in total'
                onChange={handleChangeTotal} checked={countInTotal}
              />

              <Form.Check 
                type='switch'
                id='checkbox-budget'
                label={`is archived`}
                onChange={handleChangeArchived} checked={archived}
              />
            </Col>
          </Form.Group>

          {budget && (
            <>
              <h4>Properties</h4>

              <Row className="mb-1">
                <Col xs={5} sm={4}>Created date: </Col>
                <Col xs={7} sm={8}>{budget.createdAt.toLocaleString()}</Col>
              </Row>

              <Row className="mb-1">
                <Col xs={5} sm={4}>Balance: </Col>
                <Col xs={7} sm={8}><strong>{budget.left}</strong></Col>
              </Row>

              {showExtraStats && <BudgetSpentProperties budget={budget} />}
              
              <div className="cursor-pointer text-primary" onClick={() => setShowExtraStats(!showExtraStats)}>
                <u>{showExtraStats ? 'Hide' : 'Show'} more stats</u>
              </div>
            </>
          )}
          
          {budget && showExtraStats && (
          <>
            <h3 className='mt-3'>Income allocations</h3>
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Date</th>
                  <th>Name</th>
                  <th className="text-end pe-3">Amount</th>
                </tr>
              </thead>
              <tbody>
                {getAllocations()?.map((allocation, index) => (
                  <tr key={allocation.id ? allocation.id : index}>
                    <td>{index + 1}</td>
                    <td>{allocation.createdAt.toLocaleString()}</td>
                    <td>{budgetsStore.incomesById[allocation.incomeId]?.name}</td>
                    <td className="text-end pe-3">
                      {allocation.amount}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr className={styles['summary-line']}>
                  <td colSpan={3}>
                    {budget?.allocations.length > 3 &&
                      <div className="cursor-pointer text-primary" onClick={() => setShowMoreIncome(!showMoreIncome)}>
                        <u>{showMoreIncome ? 'Hide' : 'Show more'}</u>
                      </div>
                    }
                  </td>
                  <td className='text-end pe-3'><strong>{budget?.allocatedTotal}</strong></td>
                </tr>
              </tfoot>
            
            </table>
          </>
          )}

          {budget && (budget.plannedExpences.length || null) && (
          <>
            <h3 className='mt-4'>Planned Transactions</h3>
            <TransactionsModalTable
              transactions={budget.plannedExpences}
              showAll={true}
              handleEditTransaction={handleEditTransaction}
              handleRemoveTransaction={handleRemoveTransaction}
              handleTransactionToPaid={handleTransactionToPaid}
            />
          </>
          )}

          {budget && (
          <>
            <h3 className='mt-4'>Transactions</h3>
            <TransactionsModalTable
              transactions={budget.expences}
              showAll={false}
              handleEditTransaction={handleEditTransaction}
              handleRemoveTransaction={handleRemoveTransaction}
              handleTransactionToPaid={handleTransactionToPaid}
            />
          </>
          )}

          {expences.map((expence, index) => {
            if (expence.type === 0) return null;
            return (
              <Form.Group className="mb-2" key={index}>
                <InputGroup>
                  {/* <InputGroup.Text>Expence</InputGroup.Text> */}
                  <Form.Control autoFocus type="number" placeholder="amount" value={expence.amount || ''} onChange={(event) => handleChangeExpenceAmount(index, event)} />
                  <Form.Control name="name" autoComplete='off' placeholder="name" type="text" value={expence.name || ''} onChange={(event) => handleChangeExpenceName(index, event)} />
                  <Button variant="outline-danger" size="sm" onClick={() => handleRemoveExpence(index)}><Trash /></Button>
                </InputGroup>
              </Form.Group>
            )
          })}

          {expences.some(item => item.type === 0) && 'Planned:'}

          {expences.map((expence, index) => {
            if (expence.type === 1) return null;
            return (
              <Form.Group className="mb-2" key={index}>
                <InputGroup>
                  {/* <InputGroup.Text>Planned</InputGroup.Text> */}
                  <Form.Control autoFocus type="number" placeholder="amount" value={expence.amount || ''} onChange={(event) => handleChangeExpenceAmount(index, event)} />
                  <Form.Control name="name" autoComplete='off' placeholder="name" type="text" value={expence.name || ''} onChange={(event) => handleChangeExpenceName(index, event)} />
                  <Button variant="outline-danger" size="sm" onClick={() => handleRemoveExpence(index)}><Trash /></Button>
                </InputGroup>
              </Form.Group>
            )
          })}

          <Button className="mt-2" size="sm" variant="outline-primary" onClick={() => handleAddExpence(1)}>
            + Expence
          </Button>

          <Button className="mt-2 ms-2" size="sm" variant="outline-primary" onClick={() => handleAddExpence(0)}>
            + Planned Expence
          </Button>

          {((!notes?.length && !showNotes) || null) && (
            <Button className="mt-2 ms-2" size="sm" variant="outline-primary" onClick={() => setShowNotes(true)}>
              Add notes
            </Button>
          )}

          <Row>
            <Col>
              {(notes?.length || showNotes || null) && (
                <Form.Group className="my-3" controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Notes:</Form.Label>
                  <Form.Control
                    as="textarea"
                    className='font-monospace'
                    rows={5}
                    value     = {notes || ''}
                    onChange  = {handleChangeNotes}
                  />
                </Form.Group>
              )}
            </Col>
          </Row>

        </Form>
      </Modal.Body>
      <Modal.Footer>
      {budget && (
        <Button variant={showConfirm ? "danger" : "warning"} className="me-auto float-left" onClick={handleDelete}>
          {showConfirm ? "Confirm delete?" : "Delete"}
        </Button>
      )}
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
})