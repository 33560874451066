
import { observer } from "mobx-react";

import { ReactElement } from 'react'
import { Row, Col } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';


import TotalTransactions from "../components/totalTransactions";
import TransactionsTable from "../components/transactionsTable";
import Transaction from '../../core/entities/Transaction';

import { useStores } from '../stores/rootStore'


function BudgetsDashboard(): ReactElement {
  const rootStore = useStores();
  const budgetsStore = rootStore.budgetsStore;

  const handleEditTransaction = (transaction: Transaction) => {
    budgetsStore.openEditTransactionModal(transaction);
  }

  return (
    <Container fluid="lg">
      <h2>Transactions</h2>
      <Row>
        <Col md={10}>
          <TransactionsTable 
            transactions={budgetsStore.transactions} 
            budgetsById={budgetsStore.budgetsById}
            handleEditTransaction={handleEditTransaction}
          />
        </Col>
        <Col md={2} className="text-start">
          <TotalTransactions transactions={budgetsStore.transactions} />
        </Col>
      </Row>
    </Container>
  )
}

export default observer(BudgetsDashboard);
