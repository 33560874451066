import { ReactElement } from 'react'
import { observer } from "mobx-react";

import BudgetModal from './budgetModal'
import IncomeModal from './incomeModal'
import FullBudgetModal from './budgetModalFull'
import TransactionModal from './addTransactionModal'

import { useStores } from '../../stores/rootStore'


const ModalsContainer = observer(function(): ReactElement {
  const rootStore = useStores();
  const budgetsStore = rootStore.budgetsStore;

  return (
    <div>
      {budgetsStore.showBudgetModal && 
        <BudgetModal 
          budgetsStore={budgetsStore}
          show={budgetsStore.showBudgetModal}
          handleClose={() => budgetsStore.closeBudgetModal()} 
          budget={budgetsStore.budgetToEdit}/>
      }
      {budgetsStore.showIncomeModal && 
        <IncomeModal 
          budgetsStore={budgetsStore}
          show={budgetsStore.showIncomeModal}
          handleClose={() => budgetsStore.closeIncomeModal()} 
          income={budgetsStore.incomeToEdit}/>
      }
      {budgetsStore.showFullBudgetModal && 
        <FullBudgetModal 
          budgetsStore={budgetsStore}
          show={budgetsStore.showFullBudgetModal}
          handleClose={() => budgetsStore.closeFullBudgetModal()} 
          budget={budgetsStore.fullBudgets.filter(item => item.id === budgetsStore.budgetIdToEdit)[0]}/>
      }
      {budgetsStore.showTransactionModal && 
        <TransactionModal 
          budgetsStore={budgetsStore}
          show={budgetsStore.showTransactionModal}
          handleClose={() => budgetsStore.closeEditTransactionModal()}
          budgetId={budgetsStore.budgetIdToEdit}
          transaction={budgetsStore.transactionToEdit}
        />
      }
    </div>
  )
})

export default ModalsContainer;
