import { v4 as uuidv4 } from 'uuid';
import Moment from 'moment';

import { useMemo, useState } from 'react'

import { 
  useTable, useSortBy, TableInstance, useFilters, useGlobalFilter,
  UsePaginationInstanceProps, UseSortByInstanceProps, UsePaginationState,
  UseFiltersInstanceProps, UseGlobalFiltersState, UseGlobalFiltersInstanceProps, Row as TableRow
} from 'react-table';

import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { Row, Col, Toast, ToastContainer } from 'react-bootstrap';

import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';

import Transaction from '../../core/entities/Transaction';
import Budget from '../../core/entities/Budget';
import BudgetsStore from '../stores/budgetsStore'

export type TableInstanceWithHooks<T extends object> = TableInstance<T> &
  UseFiltersInstanceProps<T> &
  UseGlobalFiltersInstanceProps<T> &
  UsePaginationInstanceProps<T> &
  UseSortByInstanceProps<T> & {
    state: UsePaginationState<T> & UseGlobalFiltersState<T>;
  };


type ComponentProps = {
  budgetsById: { [index: string]: Budget };
  budgetsStore: BudgetsStore,
}

type ImportCSVLine = {
  createdAt: Date;
  name: string;
  amount: number;
  budgetId?: string | null;
  saved? : boolean;
}

export default function TransactionsTable({ budgetsById, budgetsStore }: ComponentProps) {
  const [dateColumnIndex, setDateColumnIndex] = useState<number>(0);
  const [nameColumnIndex, setNameColumnIndex] = useState<number>(1);
  const [amountColumnIndex, setAmountColumnIndex] = useState<number>(2);
  const [csvText, setCSVText] = useState("");
  const [importTransactions, setImportTransactions] = useState<Array<ImportCSVLine>>([]);
  const [showToast, setShowToast] = useState(false);
  const [savedCount, setSavedCount] = useState(0);

  const data = useMemo(() => { 
    return importTransactions.map(transaction => {
      let color = 'inherit';
      if (transaction.budgetId) {
        color = 'lightyellow';
      } else if (transaction.saved) {
        color = 'lightgreen';
      } else if (transaction.amount > 0) {
        color = 'lightblue';
      }

      return {
        createdAt: transaction.createdAt!.toLocaleString(),
        datetime: transaction.createdAt,
        name: transaction.name,
        amount: Math.ceil(transaction.amount),
        budgetId: transaction.budgetId,
        date: Moment(transaction.createdAt!).format('YYYY-MM-DD'),
        saved: transaction.saved,
        color: color
      }
    });
  }, [importTransactions, budgetsById]);

  const budgetOptions = Object.values(budgetsById).filter(row => !row.isArchived);

  const columns: any = useMemo(() => [
    { Header: 'Date', accessor: 'createdAt',
      sortType: (rowA: any, rowB: any, columnId: string, desc: boolean) => {
        if (rowA.values.planned) {
          return 1;
        }
        if (rowB.values.planned) {
          return -1;
        }
        const a = rowA.original['datetime']
        const b = rowB.original['datetime']
        return a > b ? 1 : a < b ? -1 : 0
      },
      filter: (rows: any[], id: string, filterValue: any): any[] => {
        let days = 0;
        if (filterValue === 'past7d') {
          days = 7;
        } else if (filterValue === 'past30d') {
          days = 30;
        }
        return rows.filter(row => {
          const from = new Date(Date.now() - days * 24 * 60 * 60 * 1000);
          const rowValue = row.original.datetime;
          return rowValue >= from;
        })
      }
    },
    { 
      Header: 'Name', accessor: 'name',
      Cell: ({ row }: any) => (
        <Form.Control type="text" value={row.original.name} onChange={(e) => handleNameChange(e.target.value, row.index)} style={{ minWidth: '150px' }}/>
      ),
    },
    { Header: 'Amount', accessor: 'amount', sortType: 'basic', sortDescFirst: true,
      Footer: (info: any) => {
        const expences = useMemo(
          () =>
            info.rows.reduce((sum: number, row: any) => {
              const am = row.values.amount < 0 ? row.values.amount : 0;
              return am + sum
            }, 0),
          [info.rows]
        )
        const balance = useMemo(
          () =>
            info.rows.reduce((sum: number, row: any) => row.values.amount + sum, 0),
          [info.rows]
        )

        return <><b>E: {Math.ceil(expences)}/ B: {Math.ceil(balance)}</b></>
      },
    },
    { Header: 'Budget', accessor: 'budgetId',
      Cell: ({ row }: any) => {
        return (
          <Form.Select value={row.original.budgetId || ''} onChange={(e) => handleSelectChange(e.target.value, row.index)} style={{ minWidth: '100px' }}>
            <option value="">Do not import</option>
            {budgetOptions.map((budget) => (
              <option key={budget.id} value={budget.id}>
                {budget.name}
              </option>
            ))}
          </Form.Select>
        );
      },
      filter: (rows: TableRow[], id: string, filterValue: any): TableRow[] => {
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue === filterValue;
      });
      }
    },
  ], []);

  const tableInstance = useTable(
    { 
      columns,
      data,
      // initialState: {
      //   filters: useMemo(() => [{ id: 'createdAt', value: 'past30d' }], []),
      // } as any
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
  ) as TableInstanceWithHooks<any>;

  const { 
    getTableProps, getTableBodyProps,
    headerGroups, rows, prepareRow, footerGroups,
    setFilter, state, setGlobalFilter
  } = tableInstance;

  const { globalFilter } = state;

  const handleCSVData = () => {
    let parsedTransactions: Array<ImportCSVLine> = [];
    var allRows = csvText.split(/\r?\n|\r/);
    for (var singleRow = 0; singleRow < allRows.length; singleRow++) {
      if (singleRow === 0 || !allRows[singleRow]) {
        continue
      }
      var rowCells = allRows[singleRow].split(',');
      const date = Moment(rowCells[dateColumnIndex], "DD.MM.YYYY hh:mm:ss");
      const name = rowCells[nameColumnIndex].replace('"', '').replace('"', '');
      let amount = parseFloat(rowCells[amountColumnIndex]);
      parsedTransactions.push({createdAt: date.toDate(), name: name, amount: amount})
    }
    setImportTransactions(parsedTransactions);
  }

  const handleCSVFileUpload = (e: React.ChangeEvent<any>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target?.result) {
          setCSVText(event.target.result as string);
        }
      };
      reader.readAsText(file);
    }
  }

  const handleSave = () => {
    const transactions = importTransactions.filter(item => item.budgetId);

    setImportTransactions((prevData) =>
      prevData.map((row) =>
        transactions.some((savedItem) => savedItem.createdAt === row.createdAt && savedItem.name === row.name && savedItem.amount === row.amount)
          ? {
              ...row,
              budgetId: null, // Deselect budget
              saved: true, // Mark fas saved
            }
          : row
      )
    );

    transactions.forEach(item => {
      const newTransaction = new Transaction({
        id: uuidv4(),
        name: item.name || '',
        amount: item.amount > 0 ? item.amount : -item.amount,
        createdAt: item.createdAt,
        type: 1,
        budgetId: item.budgetId,
        note: 'imported',
      });
      budgetsStore.addTransaction(newTransaction.id, newTransaction);
    });
    
    setSavedCount(transactions.length);
    setShowToast(true);
  }

  const handleSelectChange = (selectedValue: string, rowIndex: number) => {
    setImportTransactions((prevData) =>
      prevData.map((row, index) =>
        index === rowIndex
          ? {
              ...row,
              budgetId: selectedValue,
            }
          : row
      )
    );
  };

  const handleNameChange = (newName: string, rowIndex: number) => {
    setImportTransactions((prevData) =>
      prevData.map((row, index) =>
        index === rowIndex
          ? {
              ...row,
              name: newName,
            }
          : row
      )
    );
  };

  return (
    <div className="transactions-table">
      <Row className='mt-3 mx-0'>
        <Col sm={8} xs={12}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>CSV File:</Form.Label>
              <Form.Control type="file" accept=".csv" onChange={(e) => handleCSVFileUpload(e)} />
            <Form.Label>CSV Text:</Form.Label>
            <Form.Control
              as="textarea"
              className='font-monospace'
              rows={6}
              value     = {csvText}
              onChange  = {(e)=> setCSVText(e.target.value)}
            />
          </Form.Group>
          <Button variant="primary" onClick={handleCSVData}>
            Parse
          </Button>
        </Col>
        <Col sm={4} xs={12}>
        <Form.Group className="mb-3" controlId="columnMappingDate">
            <Form.Label>Date Column Index:</Form.Label>
            <Form.Control type="number" value={dateColumnIndex} onChange={(e) => setDateColumnIndex(Number(e.target.value))} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="columnMappingName">
            <Form.Label>Name Column Index:</Form.Label>
            <Form.Control type="number" value={nameColumnIndex} onChange={(e) => setNameColumnIndex(Number(e.target.value))} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="columnMappingAmount">
            <Form.Label>Amount Column Index:</Form.Label>
            <Form.Control type="number" value={amountColumnIndex} onChange={(e) => setAmountColumnIndex(Number(e.target.value))} />
          </Form.Group>
        </Col>
      </Row>
  
      <Table className="text-start" striped bordered hover responsive size="sm" {...getTableProps()} >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
  
                  {column.isSorted ? (
                  column.isSortedDesc ? (
                    <span><CaretDownFill /></span>
                  ) : (
                    <span><CaretUpFill /></span>
                  )
                ) : null}                  
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()} style={{ backgroundColor: row.original.color }}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()} style={{ textAlign: cell.column.id === 'amount' ? 'right' : 'left' }}>{cell.render('Cell')}</td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
        <tfoot>
        {footerGroups.map(group => (
          <tr {...group.getFooterGroupProps()}>
            {group.headers.map(column => (
              <td {...column.getFooterProps()}>{column.render('Footer')}</td>
            ))}
          </tr>
        ))}
      </tfoot>
      </Table>

      <Button variant="primary" onClick={handleSave}>
        Save to budgets
      </Button>

      <ToastContainer position="top-end" className="p-3">
        <Toast onClose={() => setShowToast(false)} show={showToast} delay={10000} autohide>
          <Toast.Header>
            <strong className="me-auto">Save Notification</strong>
          </Toast.Header>
          <Toast.Body>{savedCount} transactions saved successfully!</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
}
