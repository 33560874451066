
export enum TransactionTypes {
  planned = 0,
  paid = 1,
}

export interface ITransaction {
  id: string;
  createdAt?: Date;

  name: string;
  amount: number;
  
  budgetId?: string | null,
  incomeId?: string | null,

  type?: TransactionTypes;
  currency?: string;
  note?: string | null;
}

export default class Transaction implements ITransaction {
  id;
  name;
  amount;
  type;
  currency;
  createdAt;
  budgetId;
  incomeId;
  note;

  constructor(data: ITransaction) {
    this.id = data.id;
    this.createdAt = data.createdAt || new Date();
    this.name = data.name;
    this.amount = data.amount;
    this.type = data.type || TransactionTypes.planned;
    this.currency = data.currency || 'uah';
    this.budgetId = data.budgetId || null;
    this.incomeId = data.incomeId || null;
    this.note = data.note || null;
  }

  get isPlanned() {
    return this.type === TransactionTypes.planned;
  }

  get isRegular() {
    return this.type === TransactionTypes.paid;
  }

  get isDirectIncomeType() {
    return !!this.incomeId
  }

  get isBudgetExpense() {
    return !!this.budgetId;
  }

  get isIncomeExpense() {
    return !!this.incomeId;
  }

  switchToPaid() {
    this.createdAt = new Date();
    this.type = TransactionTypes.paid;
  }

}

export type TransactionDraft = {
  amount: number | undefined,
  name: string | undefined,
  type: TransactionTypes,
  budgetId?: string | undefined,
  incomeId?: string | undefined,
  note?: string | undefined;
}
