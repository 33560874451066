import IncomeAllocation from './IncomeAllocation';
import Transaction, { TransactionTypes } from './Transaction';
import Budget, {IBudget} from './Budget';
import { calculateSum, calculateSumLastNDays } from '../utils';


// Budget aggregate root pattern

export default class FullBudget extends Budget {
  allocations: Array<IncomeAllocation>;
  transactions: Array<Transaction>;

  constructor(
    data: IBudget,
    allocations: Array<IncomeAllocation>,
    transactions: Array<Transaction>,
  ) {
    super(data);
    this.allocations = allocations || [];
    this.transactions = transactions || [];
  }

  collapse(): void {
    this.collapsed = !this.collapsed;
  }

  get expences() {
    return this.transactions.filter(transaction => transaction.type === TransactionTypes.paid);
  }

  get expencesLast7d() {
    const from = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    return this.expences.filter(expence => expence.createdAt >= from);
  }

  get expencesLast30d() {
    const from = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
    return this.expences.filter(expence => expence.createdAt >= from);
  }

  get plannedExpences() {
    return this.transactions.filter(transaction => transaction.type === TransactionTypes.planned);
  }

  get amountAllocated() {
    return calculateSum(this.allocations, 'amount');
  }

  get spent() {
    return calculateSum(this.expences, 'amount');
  }

  get left(): number {
    return this.amountAllocated - this.spent;
  }

  get spent7d(): number {
    return calculateSum(this.expencesLast7d, 'amount');
  }
  
  get spent30d(): number {
    return calculateSum(this.expencesLast30d, 'amount');
  }

  get spentYTD(): number {
    const now = new Date();
    const start = new Date(now.getFullYear(), 0, 1);
    const expences = this.expences.filter(expence => expence.createdAt >= start);

    return calculateSum(expences, 'amount');
  }

  get allocatedTotal(): number {
    return calculateSum(this.allocations, 'amount');
  }

  get allocated30d(): number {
    return calculateSumLastNDays(this.allocations, 'amount', 30);
  }

  get allocatedYTD(): number {
    const now = new Date();
    const start = new Date(now.getFullYear(), 0, 1);
    const allocations = this.allocations.filter(allocation => allocation.createdAt >= start);

    return calculateSum(allocations, 'amount');
  }
}