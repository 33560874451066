import { ReactElement } from 'react'
import { observer } from "mobx-react";

import Button from 'react-bootstrap/Button'

import BudgetsList from '../components/budgetCard/budgetsList'

import { useStores } from '../stores/rootStore'


function BudgetsDashboard(): ReactElement {
  const rootStore = useStores();
  const budgetsStore = rootStore.budgetsStore;

  return (
    <div>
      <Button variant="primary" onClick={() => budgetsStore.openBudgetModal()}>Create Budget</Button>{" "}
      <p>Total on accounts: { rootStore.demoNumbersMode? budgetsStore.totalAmountNeeded / 10 : budgetsStore.totalAmountNeeded }</p>

      <BudgetsList budgets={budgetsStore.publicBudgets} />
      <p>- All -</p>
      <BudgetsList budgets={budgetsStore.budgets} />
    </div>
  )
}

export default observer(BudgetsDashboard);
