
import { observer } from "mobx-react";

import { ReactElement } from 'react'
import { Row, Col } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';


import BudgetsTable from "../components/budgetsTable";

import { useStores } from '../stores/rootStore'


function BudgetsDashboard(): ReactElement {
  const rootStore = useStores();
  const budgetsStore = rootStore.budgetsStore;

  return (
    <Container fluid="xl">
      <h2>Budgets</h2>
      <Row>
        <Col md={10} className="m-auto">
          <BudgetsTable 
            budgets={budgetsStore.fullBudgets}
            openFullBudgetModal={(budgetId) => budgetsStore.openFullBudgetModal(budgetId)}
          />
        </Col>
      </Row>
    </Container>
  )
}

export default observer(BudgetsDashboard);
