import Moment from 'moment';

import { useMemo, useState } from 'react'

import { 
  useTable, useSortBy, TableInstance, useFilters, useGlobalFilter,
  UsePaginationInstanceProps, UseSortByInstanceProps, UsePaginationState,
  UseFiltersInstanceProps, UseGlobalFiltersState, UseGlobalFiltersInstanceProps, UseFiltersState
} from 'react-table';

import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form'
import { Row, Col } from 'react-bootstrap';

import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';

import FullBudget from '../../core/entities/FullBudget';


export type TableInstanceWithHooks<T extends object> = TableInstance<T> &
  UseFiltersInstanceProps<T> &
  UseFiltersState<T> &
  UseGlobalFiltersInstanceProps<T> &
  UsePaginationInstanceProps<T> &
  UseSortByInstanceProps<T> & {
    state: UsePaginationState<T> & UseGlobalFiltersState<T>;
  };


type ComponentProps = {
  budgets: FullBudget[];
  openFullBudgetModal: (id: string) => void;
}


export default function TransactionsTable({ budgets, openFullBudgetModal }: ComponentProps) {
  const [showArchived, setShowArchived] = useState(false);

  const data = useMemo(() => {

    return budgets.map(item => ({
      id: item.id,
      date: Moment(item.createdAt!).format('YYYY-MM-DD'),
      name: item.name,
      balance: item.left,
      spent7d: item.spent7d,
      spent30d: item.spent30d,
      allocated30d: item.allocated30d,
      spentYTD: item.spentYTD,
      allocatedYTD: item.allocatedYTD,
      archived: item.isArchived,
    }));
  }, [budgets]);

  function calculateSum(rows: any, id: string) {
    return rows.reduce((sum: number, row: any) => row.values[id] + sum, 0)
  }

  const columns: any = useMemo(() => [
    { Header: 'Name', accessor: 'name', Footer: 'Total', sortType: 'basic', className: "fw-bold text-start"},
    { Header: 'Balance', accessor: 'balance', sortType: 'basic', sortDescFirst: true,
      Footer: (info: any) => {return useMemo(() => calculateSum(info.rows, info.column.id), [info.rows, info.column.id])},
    },
    { Header: 'Spent 7d', accessor: 'spent7d', sortType: 'basic', sortDescFirst: true,
      Footer: (info: any) => {return useMemo(() => calculateSum(info.rows, info.column.id), [info.rows, info.column.id])},
    },
    { Header: 'Spent 30d', accessor: 'spent30d', sortType: 'basic', sortDescFirst: true,
      Footer: (info: any) => {return useMemo(() => calculateSum(info.rows, info.column.id), [info.rows, info.column.id])},
    },
    { Header: 'Allocated 30d', accessor: 'allocated30d', sortType: 'basic', sortDescFirst: true,
      Footer: (info: any) => {return useMemo(() => calculateSum(info.rows, info.column.id), [info.rows, info.column.id])},
    },
    { Header: 'Spent YTD', accessor: 'spentYTD', sortType: 'basic', sortDescFirst: true,
      Footer: (info: any) => {return useMemo(() => calculateSum(info.rows, info.column.id), [info.rows, info.column.id])},
    },
    { Header: 'Allocated YTD', accessor: 'allocatedYTD', sortType: 'basic', sortDescFirst: true,
      Footer: (info: any) => {return useMemo(() => calculateSum(info.rows, info.column.id), [info.rows, info.column.id])},
    },
    { Header: 'Is Archived', accessor: 'archived',
      filter: (rows: any[], id: string, filterValue: any) => {
        if (filterValue === false) {
            return rows.filter(row => {
              const rowValue = row.values[id];
              return rowValue === filterValue;
            });
        } else {
          return rows;
        }
      },
    },
  ], []);

  const tableInstance = useTable(
    { columns, data,
      autoResetSortBy: false,
      autoResetFilters: false,
      initialState: {
        filters: useMemo(() => [{ id: 'archived', value: false }], []),
        hiddenColumns: ['archived']
      } as any
    } as any,
    useFilters,
    useGlobalFilter,
    useSortBy,
  ) as TableInstanceWithHooks<any>;

  const { 
    getTableProps, getTableBodyProps,
    headerGroups, rows, prepareRow, footerGroups,
    setFilter
  } = tableInstance;

  const handleChangeShowArchived = () => {
    setShowArchived(!showArchived);
    setFilter("archived", !showArchived);
  }

  return (
    <div>
      <Row className='my-3 text-start'>
        <Col>
          <div className='d-inline-block ms-2 table-filter-pill'>
            <Form.Check 
              required
              type='switch'
              id='checkbox-total'
              label='Show archived'
              onChange={handleChangeShowArchived} checked={showArchived}
            />
          </div>
        </Col>
      </Row>

      <div className="table-responsive">
      <Table className="text-end" hover {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps({className: column.className, ...column.getSortByToggleProps()})}>
                  {column.render('Header')}
  
                  {column.isSorted ? (
                  column.isSortedDesc ? (
                    <span><CaretDownFill /></span>
                  ) : (
                    <span><CaretUpFill /></span>
                  )
                ) : null}                  
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="cursor-pointer">
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps({className: row.original.archived ? "table-secondary" : ""})} onClick={() => openFullBudgetModal(row.original.id)}>
                {row.cells.map((cell: any, i) => {
                  return (
                    <td {...cell.getCellProps({className: cell.column.className})}>{cell.render('Cell')}</td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
        <tfoot>
        {footerGroups.map(group => (
          <tr {...group.getFooterGroupProps()}>
            {group.headers.map((column: any) => (
              <th {...column.getFooterProps({className: column.className})}>{column.render('Footer')}</th>
            ))}
          </tr>
        ))}
      </tfoot>
      </Table>
      </div>
    </div>
  );
}