import FullBudget from "../entities/FullBudget";
import { SimpleBudget } from "../entities/CompositeTypes";
import { TransactionDraft } from '../entities/Transaction';

import { getAISuggestionsForSpeech, getAISuggestion } from "../infrastructure/firebase";


export type SuggestionsResponseFromAI = {
  message: string,
  isError: boolean,
  budgets: string,
  transcription?: string
}

export type SuggestionsResponse = {
  message: string,
  isError: boolean,
  newTransactions?: TransactionDraft[]
  transcription?: string,
}


export default class AISuggestionsService {

  prepareBudgetsForAI(budgets: FullBudget[]): SimpleBudget[] {
    return budgets.map(
      budget => ({
        name: budget.name,
        transactions:
          budget.transactions.slice(0, 2).map(
              t => ({name: t.name, amount: t.amount})
          )
      })
    )
  }

  async getSuggestionsBySpeech(base64Audio: string, format: string, budgets: FullBudget[]): Promise<SuggestionsResponse> {
    const start = performance.now()
    const budgetsForAI = this.prepareBudgetsForAI(budgets);
    const data = {
      audio: base64Audio,
      format: format,
      budgets: budgetsForAI
    }

    try {
      var response = await getAISuggestionsForSpeech(data);
    } catch (e: any) {
      return {
        message: e.message,
        isError: true,
        transcription: '',
        newTransactions: []
      }
    }

    const responseData = response.data as SuggestionsResponseFromAI;

    
    if (responseData.isError) {
      const end = performance.now()
      console.log('Suggestion took', end - start, 'ms')
      console.log('ai message: ', responseData.message);
      return {
        message: responseData.message,
        isError: responseData.isError,
        transcription: responseData.transcription
      }
    }

    try {
      const budgetsFromAI: SimpleBudget[] = JSON.parse(responseData.budgets);
      var suggestedResponse = await this.processSuggestions(budgetsForAI, budgetsFromAI, budgets)
  } catch (e: any) {
      console.log('error parsing budgets', e)
      throw new Error(e.message)
  }

    const end = performance.now()
    console.log('Suggestion took', end - start, 'ms')

    return {
      message: responseData.message,
      isError: responseData.isError,
      transcription: responseData.transcription,
      newTransactions: suggestedResponse
    }
  }

  async getAISuggestions(query: string, budgets: FullBudget[]): Promise<SuggestionsResponse> {
    const start = performance.now()
    const budgetsForAI = this.prepareBudgetsForAI(budgets);

    const data = {
      text: query,
      budgets: budgetsForAI
    }

    const response = await getAISuggestion(data);

    const responseData = response.data as SuggestionsResponseFromAI;
    
    if (responseData.isError) {
      console.log('ai message: ', responseData.message);
      const end = performance.now()
      console.log('Suggestion took', end - start, 'ms')
      return {
        message: responseData.message,
        isError: responseData.isError,
        transcription: responseData.transcription
      }
    }

    try {
        const budgetsFromAI: SimpleBudget[] = JSON.parse(responseData.budgets);
        var suggestedResponse = await this.processSuggestions(budgetsForAI, budgetsFromAI, budgets)
    } catch (e: any) {
        console.error('error parsing budgets', e)
        return {
          message: 'error parsing response from AI',
          isError: true,
          transcription: responseData.transcription,
          newTransactions: []
        };
    }

    const end = performance.now()
    console.log('Suggestion took', end - start, 'ms')   

    return {
      message: responseData.message,
      isError: responseData.isError,
      transcription: responseData.transcription,
      newTransactions: suggestedResponse
    };
  }

  async processSuggestions(budgetsInput: SimpleBudget[], budgetsOutput: SimpleBudget[], budgets: FullBudget[]) {
    let newTransactions: TransactionDraft[] = []

    // find new transactions that was not in budgetsInput
    budgetsOutput.forEach(budgetOutput => {
      const budgetInput = budgetsInput.find(budgetInput => budgetInput.name === budgetOutput.name)
      if (!budgetInput) {
        console.error(`budget ${budgetOutput.name} not found`)
        return newTransactions;
      }
      budgetOutput.transactions.forEach(transactionOutput => {
        const transactionInput = budgetInput.transactions.find(transactionInput => transactionInput.name === transactionOutput.name)
        if (!transactionInput) {
          console.log('new transaction', transactionOutput.name, transactionOutput.amount, 'in budget', budgetOutput.name);
          const budgetId = budgets.find(budget => budget.name === budgetOutput.name)?.id
          // new transaction
          const newTransaction: TransactionDraft = {
            name: transactionOutput.name,
            amount: transactionOutput.amount,
            type: 1,
            budgetId: budgetId,
          }
          newTransactions.push(newTransaction)
          console.log(newTransaction);
        }
        // check if transaction amount changed
        if (transactionInput && transactionInput.amount !== transactionOutput.amount) {
          console.log('transaction amount changed', transactionOutput.name, transactionOutput.amount);
        }
        // check if transaction name changed
        if (transactionInput && transactionInput.name !== transactionOutput.name) {
          console.log('transaction name changed', transactionOutput.name, transactionOutput.amount);
        }
      })

    })

    return newTransactions;

  }

}