
import { observer } from "mobx-react";
import { ReactElement, useEffect, useState } from 'react'

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card';
import { Row, Col } from 'react-bootstrap';


import { useStores } from '../stores/rootStore'
import NoteModal from '../components/modals/noteModal'
import Note from "../../core/entities/Note";


function BudgetsDashboard(): ReactElement {
  const rootStore = useStores();
  const notesStore = rootStore.notesStore;

  const [showAddNotesModal, setShowModal] = useState(false);
  const [selectedNote, setSelectedNote] = useState<Note | null>(null);

  useEffect(() => {
    notesStore.fetchNotes();
  }, [notesStore]);

  const handleEditNote = (note: Note) => {
    setSelectedNote(note);
    setShowModal(true);
  }

  const handleCloseModal = () => {
    setSelectedNote(null);
    setShowModal(false);
  }

  const changeOrderUp = (e: any, note: Note) => {
    e.stopPropagation();
    notesStore.changeOrderUp(note);
  }


  return (
    <Container fluid="lg" className="">
      <h2>Notes</h2>
      <Button variant="primary" onClick={() => setShowModal(true)}>Add Note</Button>
      <div className="text-start mt-3">
      <Row xs={1} md={2} className="g-3">
        {notesStore.notes.map((note, i) => {
          return (
            <Col className="cursor-pointer" key={note.id}>
              <Card className="h-100" onClick={() => handleEditNote(note)}>
                <Card.Body>
                  <Card.Title>{note.title}</Card.Title>
                  {i > 0 &&
                    <Button variant="light" size="sm" className="mb-2" onClick={(e) => changeOrderUp(e, note)}>move upper</Button>
                  }
                  {/* <Card.Text> */}
                    <pre className="prettyprint">{note.text}</pre>

                  {/* </Card.Text> */}
                </Card.Body>
              </Card>
            </Col>
          )
        })}
      </Row>
      </div>

      {showAddNotesModal && <NoteModal
        notesStore={notesStore}
        note={selectedNote}
        show={showAddNotesModal}
        handleClose={() => handleCloseModal()}
      />}
    </Container>
  )
}

export default observer(BudgetsDashboard);
