import Budget from "../entities/Budget";
import { IBudgetItem } from "../entities/BudgetItem";

import FirestoreBudgetRepository from "../infrastructure/FirestoreBudgetRepository";


export default class BudgetService {
  budgetRepository;

  constructor(budgetRepository: FirestoreBudgetRepository) {
    this.budgetRepository = budgetRepository;
  }

  async getAll(userId?: string) {
    return this.budgetRepository.getAll(userId!);
  }

  async create(obj: Budget, userId?: string) {
    return this.budgetRepository.create(obj.id, obj, userId!);
  }

  async update(budget: Budget) {
    return this.budgetRepository.update(budget.id, budget);
  }

  async delete(budgetId: string) {
    return this.budgetRepository.delete(budgetId);
  }

  async refresh(budget: Budget) {
    budget.refresh();
    return this.budgetRepository.update(budget.id, budget);
  }

  async collapse(budget: Budget) {
    budget.collapse();
    return this.budgetRepository.update(budget.id, budget);
  }

  async addItem(budgetId: string, name: string, amount: number) {
    return this.budgetRepository.addItem(budgetId, name, amount);
  }

  async deleteItem(budgetId: string, itemId: string) {
    return this.budgetRepository.deleteItem(budgetId, itemId);
  }

  async markItemAsPaid(budgetId: string, item: IBudgetItem, partialAmount?: number) {
    item.markAsPaid(partialAmount)
    return this.budgetRepository.updateItem(budgetId, item.id, item);
  }

}
