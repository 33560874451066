import Income, {IIncome} from './Income';
import IncomeAllocation from './IncomeAllocation';
import Transaction from './Transaction';


export default class FullIncome extends Income {
  allocations: Array<IncomeAllocation>;
  transactions: Array<Transaction>;

  constructor(
    data: IIncome,
    allocations: Array<IncomeAllocation>,
    transactions: Array<Transaction>,
  ) {
    super(data);
    this.allocations = allocations || [];
    this.transactions = transactions || [];
  }

  get totalAllocated() {
    return this.allocations.reduce((total, item) => total + (item.amount || 0), 0);
  }

  get totalExpences() {
    return this.transactions.reduce((total, item) => total + (item.amount || 0), 0);
  }

  get free() {
    return this.amount - this.totalAllocated - this.totalExpences;
  }

}