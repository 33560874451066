export interface IIncomeSource {
  name: string;
  amount: number | null;
  createdAt: Date;
  currency?: string;
  note: string | null;
}

export interface IIncome {
  id: string;
  createdAt: Date;
  name: string;
  sources: IIncomeSource[];
  notes: string | null;
}

export default class Income implements IIncome {
  id;
  createdAt;
  name;
  sources: IIncomeSource[];
  notes: string | null;

  constructor(data: IIncome) {
    this.id = data.id;
    this.createdAt = data.createdAt || new Date();
    this.name = data.name;
    this.sources = data.sources || [];
    this.notes = data.notes || null;
  }

  get amount() {
    return this.sources.reduce((acc, source) => acc + (source.amount || 0), 0);
  }

  get currency() {
    return this.sources[0].currency;
  }

}
