export interface INote {
  id: string;
  createdAt: Date;
  title: string;
  text: string;
  order?: number;
}

export default class Note implements INote {
  id;
  createdAt;
  title;
  text;
  order;

  constructor(data: INote) {
    this.id = data.id;
    this.createdAt = data.createdAt || new Date();
    this.title = data.title;
    this.text = data.text;
    this.order = data.order;
  }
}