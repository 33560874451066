import {
  doc,
  setDoc, getDoc, getDocs, updateDoc, deleteDoc,
  query, orderBy, limit, where,
  CollectionReference
} from "firebase/firestore";

// function identity<T>(arg: T): T {
//   return arg;
// }

export interface IWrite<T> {
  create(id: string, item: T): Promise<T>;
  update(id: string, item: T): Promise<T>;
  delete(id: string): Promise<void>;
}

export interface IRead<T> {
  getAll(userId: string): Promise<T[]>;
  getById(id: string): Promise<T>;
}

export interface IFirestoreConversion<T> {
  toFirestore(item: T): object;
  fromFirestore(obj: any): T;
}


export abstract class BaseFirestoreRepository<T> implements IWrite<T>, IRead<T>, IFirestoreConversion<T> {
  public readonly _collection: CollectionReference;

  orderBy = orderBy("createdAt", "desc");

  constructor(collection: CollectionReference) {
    this._collection = collection
  }

  protected async _load(userId: string) {
    try {
      let options = [
        this.orderBy,
        where("user", "==", userId),
        limit(1000)
      ]
      const q = query(this._collection, ...options);
      const querySnapshot = await getDocs(q);

      return querySnapshot.docs.map(snap => {
        return {...snap.data(), id: snap.id}
      })
    } catch (e) {
      console.log(e)
    }
    return [];
  }

  toFirestore(item: T): object {
    throw new Error("Method not implemented.");
  }

  fromFirestore(obj: any): T {
    throw new Error("Method not implemented.");
  }

  async getAll(userId: string) {
    const data = await this._load(userId);
    return data.map(this.fromFirestore);
  }

  async getById(objId: string) {
    const docSnap = await getDoc(doc(this._collection, objId))

    if (!docSnap.exists()) {
      console.log("No such document!");
    }

    return this.fromFirestore(docSnap.data());
  }

  async create(objId: string, item: T, userId?: string) {
    await setDoc(
      doc(this._collection, objId),
      {...this.toFirestore(item), user: userId}
    )
    return this.getById(objId)
  }

  async update(objId: string, data: T) {
    await updateDoc(doc(this._collection, objId), this.toFirestore(data))
    return this.getById(objId);
  }

  async delete(objId: string) {
    await deleteDoc(doc(this._collection, objId))
  }

}
