import React, { FC, ReactElement } from 'react';
import {observer} from "mobx-react";

import styles from '../../../styles/Home.module.css'
import Budget from '../../../core/entities/Budget';
import BudgetCard from './budgetCard';


type BudgetsListProps = {
  budgets: Budget[],
}

const BudgetsList: FC<BudgetsListProps> = ({budgets}): ReactElement => {

  return (
    <div className={styles.grid}>
      {budgets.map(budget => (
        <BudgetCard budget={budget} key={budget.id} />
      ))}
    </div>
  )
}

export default observer(BudgetsList);