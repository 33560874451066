import { Timestamp } from "firebase/firestore";

import Transaction from '../entities/Transaction';

import { BaseFirestoreRepository } from "./baseFirestoreRepository";


export class TransactionRepository extends BaseFirestoreRepository<Transaction>{
  toFirestore(transaction: Transaction) {
    return {...transaction, createdAt: Timestamp.fromDate(transaction.createdAt)}
  }

  fromFirestore(obj: any): Transaction {
    return new Transaction({
      id:         obj.id!,
      name:       obj.name,
      amount:     obj.amount,
      type:       obj.type,
      currency:   obj.currency,
      createdAt:  obj.createdAt!.toDate(),
      budgetId:   obj.budgetId,
      incomeId:   obj.incomeId,
      note:       obj.note,
    })
  }
}
