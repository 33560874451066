import { Timestamp } from "firebase/firestore";

import Income from '../entities/Income';

import { BaseFirestoreRepository } from "./baseFirestoreRepository";


export class IncomeRepository extends BaseFirestoreRepository<Income>{
    toFirestore(item: Income) {
      return {
        ...item,
        sources: item.sources.map(source => {
          return {
            ...source,
            note: source.note || null,
            createdAt: source.createdAt ? Timestamp.fromDate(source.createdAt) : Timestamp.now()
          }
        }),
        createdAt: Timestamp.fromDate(item.createdAt)
      }
    }

    fromFirestore(obj: any): Income {      
      return new Income({
        id:         obj.id!,
        name:       obj.name,
        sources:    obj.sources.map((source: any) => {
          return {
            ...source,
            createdAt: source.createdAt?.toDate()
          }
        }),
        createdAt:  obj.createdAt!.toDate(),
        notes:      obj.notes,
      })
    }
}
