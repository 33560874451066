
export interface IIncomeAllocation {
  id: string;
  createdAt: Date;

  amount: number | undefined;

  incomeId: string;
  budgetId: string;

  note?: string | null;
}

export default class IncomeAllocation implements IIncomeAllocation {
  id;
  createdAt;
  amount;
  incomeId;
  budgetId;
  note;

  constructor(data: IIncomeAllocation) {
    this.id = data.id;
    this.createdAt = data.createdAt || new Date();
    this.amount = data.amount;
    this.incomeId = data.incomeId;
    this.budgetId = data.budgetId;
    this.note = data.note || null;
  }

}
