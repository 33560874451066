import { Timestamp, orderBy } from "firebase/firestore";

import IncomeAllocation from '../entities/IncomeAllocation';

import { BaseFirestoreRepository } from "./baseFirestoreRepository";


export class IncomeAllocationRepository extends BaseFirestoreRepository<IncomeAllocation>{

    orderBy = orderBy("createdAt", "asc");

    toFirestore(item: IncomeAllocation) {
      return {...item, createdAt: Timestamp.fromDate(item.createdAt)}
    }

    fromFirestore(obj: any): IncomeAllocation {
      return new IncomeAllocation({
        id:         obj.id!,
        amount:     obj.amount,
        incomeId:   obj.incomeId,
        budgetId:   obj.budgetId,
        createdAt:  obj.createdAt!.toDate(),
        note:       obj.note,
      })
    }
}
