import { observer } from 'mobx-react';
import React, { useState, ChangeEvent } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'

import styles from '../../../styles/Home.module.css'

import { IBudget } from '../../../core/entities/Budget';
import { useStores } from '../../stores/rootStore'


type Props = {
  budget: IBudget,
  isEditMode: boolean,
  setEditMode: () => void,
}

function BudgetItemForm({budget, isEditMode, setEditMode}: Props) {
  const { budgetsStore } = useStores();
  const [name, setName] = useState('');
  const [amount, setAmount] = useState<number | undefined>(undefined);

  const handleChangeName = (event: ChangeEvent<HTMLInputElement>): void => {
    setName(event.target.value)
  }

  const handleChangeAmount = (event: ChangeEvent<HTMLInputElement>): void => {
    setAmount(parseInt(event.target.value))
  }

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();

    if (!isEditMode) {
      setEditMode();
      return;
    }

    if (!amount || !name) {
      alert('form is not valid');
      return
    }

    budgetsStore.addItem(budget.id, name, amount);
    
    setName('');
    setAmount(undefined)
  }

  return (
    <div className={styles.cardForm}>
      <InputGroup>
        {isEditMode && 
          <>
            <Form.Control autoFocus name="name" placeholder="name" type="text" value={name} onChange={handleChangeName} />
            <Form.Control type="number" placeholder="amount" value={amount || ''} onChange={handleChangeAmount} />
          </>
        }
        <Button variant="outline-primary" onClick={handleSubmit}>Add item</Button>
      </InputGroup>
    </div>
  )
}

export default observer(BudgetItemForm);