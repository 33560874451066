import { observer } from "mobx-react";
import Button from 'react-bootstrap/Button'

import { useStores } from '../stores/rootStore'


function Settings() {
  const { budgetsStore } = useStores();

  return (
    <div>
      <Button variant="outline-primary" onClick={() => budgetsStore.changeLock() }>Lock {budgetsStore.lock && 'ON'}</Button>
      <Button variant="outline-primary" onClick={() => budgetsStore.changeRandomize() }>Randomize {budgetsStore.randomize && 'ON'}</Button>
      <Button variant="outline-primary" onClick={() => budgetsStore.changeAiSetting() }>AI {budgetsStore.ai && 'ON'}</Button>
    </div>
  )
}

export default observer(Settings);
