import { IBudgetItem } from './BudgetItem'
import { calculateSum } from '../utils'

export type BudgetSource = {
  budgetId: string,
  budgetItemId: string
}

export interface IBudget {
  id: string;
  createdAt?: Date;
  name: string;
  amount?: number;
  collapsed?: boolean;
  items?: IBudgetItem[];
  source?: BudgetSource | null;
  isArchived?: boolean;
  countInTotal?: boolean;
  notes: string | null;
}

export default class Budget implements IBudget {
  id;
  createdAt;
  name;
  amount?;
  collapsed?;
  items?: IBudgetItem[];
  notes: string | null;
  source?: BudgetSource | null;
  isArchived?: boolean;
  countInTotal?: boolean;

  constructor(data: IBudget) {
    this.id = data.id;
    this.createdAt = data.createdAt || new Date();
    this.name = data.name;
    this.amount = data.amount;
    this.collapsed = data.collapsed || false;
    this.items = data.items || [];
    this.notes = data.notes || null;
    this.source = data.source || null;
    this.isArchived = data.isArchived || false;
    this.countInTotal = data.countInTotal || true;
  }

  collapse(): void {
    this.collapsed = !this.collapsed;
  }

  get safeAmount() {
    return this.amount || 0;
  }

  get safeItems() {
    return this.items || [];
  }

  get left() {
    if (!this.amount || !this.items?.length) {
      return 0;
    }
    return this.amount - calculateSum(this.items, 'amountPaid');
  }

  get free(): number {
    if (!this.amount || !this.items?.length) {
      return 0;
    }
    return (this.amount || 0) - calculateSum(this.items, 'amount');
  }

  get plannedUnpaidAmount(): number {
    if (!this.items?.length) {
      return 0;
    }
    return calculateSum(this.items.filter((item: IBudgetItem) => !item.paid), 'left');
  }

  refresh() {
    if (!this.items?.length) {
      return;
    }

    this.collapsed = false;
    this.items.forEach(item => {
      item.refresh()
    })
  }

}
