import './styles/App.css';
import { observer } from "mobx-react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import { useStores } from './app/stores/rootStore'
import Transactions from './app/pages/transactions'
import BudgetsPage from './app/pages/budgets'
import BudgetsList from './app/pages/budgetsOld'
import BetaDashboard from './app/pages/dashboard'
import BudgetModalContainer from './app/components/modals/ModalsContainer'
import Settings from './app/pages/settings';
import Header from './app/components/Header';
import Planning from './app/pages/planning';
import Import from './app/pages/import';


function App() {
  const { authStore } = useStores();

  return (
    <div className="App">
      <Router>
  
        <Header />

        <main className="main">

            <BudgetModalContainer />

            {authStore.loggedIn &&
              <Routes>
                <Route path="/" element={<BetaDashboard />} />
                <Route path="/transactions" element={<Transactions />} />
                <Route path="/budgets" element={<BudgetsPage />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/budgets-old" element={<BudgetsList />} />
                <Route path="/planning" element={<Planning />} />
                <Route path="/import" element={<Import />} />
              </Routes>
            }

        </main>
      </Router>
    </div>
  );
}

export default observer(App);
