import { UserInfo, onAuthStateChanged, signOut } from "firebase/auth";

import { makeAutoObservable } from 'mobx';

import { firebaseAuth, signInWithGoogle } from '../../core/infrastructure/firebase'

export default class AuthStore {
    loggedIn = false;
    user: UserInfo | null = null;

    constructor() {
        makeAutoObservable(this);
        onAuthStateChanged(firebaseAuth, user => this.onAuthStateChanged(user));
    }

    get userId() {
        return this.user?.uid
    }

    onAuthStateChanged(user: UserInfo | null) {
        this.user = user;
        this.loggedIn = !!user;
    }

    *signInWithGoogle() {
        yield signInWithGoogle();
    }

    *signOut() {
        yield signOut(firebaseAuth);
    }
}
