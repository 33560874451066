export interface IBudgetItem {
  id: string;
  name: string;
  amount: number;
  amountPaid: number;
  paid: boolean;

  isPartialPaid: boolean;
  left: number;

  markAsPaid(partialAmount?: number): void;
  refresh(): void;
}

export class BudgetItem implements IBudgetItem {
  id;
  name;
  amount;
  amountPaid;
  paid;

  constructor(id: string, name: string, amount: number, amountPaid: number, paid: boolean) {
    this.id = id;
    this.name = name;
    this.amount = amount;
    this.amountPaid = amountPaid;
    this.paid = paid;
  }

  markAsPaid(partialAmount: number): void {
    if (partialAmount) {
      this.amountPaid = this.amountPaid + partialAmount;
    } else {
      this.amountPaid = this.amount;
    }

    if (this.amountPaid >= this.amount) {
      this.paid = true;
    }
  }

  refresh() {
    this.amountPaid = 0;
    this.paid = false;
  }

  get isPartialPaid(): boolean {
    return !this.paid && this.amountPaid > 0;
  }

  get left(): number {
    return this.amount - this.amountPaid;
  }

}

export default BudgetItem;
