import { v4 as uuidv4 } from 'uuid';

import {
  doc,
  updateDoc,
  Timestamp, arrayUnion
} from "firebase/firestore";

import Budget, { IBudget } from '../entities/Budget';
import BudgetItem, { IBudgetItem } from '../entities/BudgetItem';
import { calculateSum } from '../utils'

import { BaseFirestoreRepository } from "./baseFirestoreRepository";


type BudgetSourceDTO = {
  budgetId: string,
  budgetItemId: string
}

type FirestoreBudgetDTO = {
  id: string;
  createdAt?: Timestamp;
  name: string;
  amount: number;
  collapsed: boolean;
  items: FirestoreBudgetItemDTO[];
  user?: string;
  source?: BudgetSourceDTO | null;
  isArchived?: boolean;
  countInTotal?: boolean;
  notes?: string;
}

type FirestoreBudgetItemDTO = {
  id: string;
  name: string;
  amount: number;
  amountPaid: number;
  paid: boolean;
}

function itemFromFirestore(obj: FirestoreBudgetItemDTO): IBudgetItem {
  return new BudgetItem(obj.id, obj.name, obj.amount, obj.amountPaid, obj.paid)
}

function itemToFirestore(item: IBudgetItem): FirestoreBudgetItemDTO {
  return {
    id: item.id,
    name: item.name,
    amount: item.amount,
    amountPaid: item.amountPaid,
    paid: item.paid,
  }
}


export default class FirestoreBudgetRepository extends BaseFirestoreRepository<Budget>{
  toFirestore(item: Budget) {
    return {
      id:            item.id,
      name:          item.name,
      amount:        item.safeAmount,
      collapsed:     item.collapsed,
      items:         item.safeItems.map(item => itemToFirestore(item)),
      source:        item.source,
      isArchived:    item.isArchived,
      countInTotal:  item.countInTotal,
      createdAt:     Timestamp.fromDate(item.createdAt),
      notes:         item.notes,
    }
  }

  fromFirestore(obj: FirestoreBudgetDTO): Budget {
    const items = obj.items.map(item => itemFromFirestore(item))
    const isArchived = !!obj.isArchived;
    const countInTotal = !!obj.countInTotal;
    const source = obj.source ? obj.source : null;

    return new Budget({
      id:             obj.id,
      name:           obj.name,
      createdAt:      obj.createdAt && obj.createdAt!.toDate(),
      collapsed:      obj.collapsed,
      countInTotal:   countInTotal,
      isArchived:     isArchived,
      source:         source,
      amount:         obj.amount,
      items:          items,
      notes:          obj.notes || null,
    })
  }
  
  async update(budgetId: string, data: Budget) {
    await updateDoc(doc(this._collection, budgetId), this.toFirestore(data))
    await this.updateLinkedBudget(data);
    return data;
  }
  
  async addItem(budgetId: string, name: string, amount: number) {
    const newItem: FirestoreBudgetItemDTO = {id: uuidv4(), name: name, amount: amount, amountPaid: 0, paid: false}
    await updateDoc(doc(this._collection, budgetId), {items: arrayUnion({
      createdAt: Timestamp.now(),
      ...newItem
    })})

    const budget = await this.getById(budgetId);
    await this.updateLinkedBudget(budget);

    return itemFromFirestore(newItem);
  }

  async updateItem(budgetId: string, itemId: string, data: IBudgetItem) {
    const budget = await this.getById(budgetId);
    const items = budget.safeItems.filter(item => item.id !== itemId);
    const updatedItems = [...items, data];
    const updatedItemsJson = updatedItems.map(itemToFirestore);
    await updateDoc(doc(this._collection, budgetId), {items: updatedItemsJson})

    budget.items = updatedItems;
    await this.updateLinkedBudget(budget);

    return itemFromFirestore(data);
  }

  async deleteItem(budgetId: string, itemId: string) {
    const budget = await this.getById(budgetId);
    const items = budget.safeItems.filter(item => item.id !== itemId);
    const itemsJson = items.map(itemToFirestore);
    await updateDoc(doc(this._collection, budgetId), {items: itemsJson})
    budget.items = items;
    await this.updateLinkedBudget(budget);
  }

  // deprecated
  async updateLinkedBudget(budget: IBudget) {
    if (!budget.source) {
      return;
    }
    const linkedBudget = await this.getById(budget.source.budgetId);
    const linkedBudgetItem = linkedBudget.items?.find(item => item.id === budget.source!.budgetItemId)
    if (linkedBudgetItem) {
      const amountPaid = calculateSum(budget.items || [], 'amountPaid');
      linkedBudgetItem.amountPaid = amountPaid;
      return await this.updateItem(linkedBudget.id, linkedBudgetItem.id, linkedBudgetItem)
    }
  }
}
