import Moment from 'moment';

import { useMemo, useState } from 'react'

import { 
  useTable, useSortBy, TableInstance, useFilters, useGlobalFilter,
  UsePaginationInstanceProps, UseSortByInstanceProps, UsePaginationState,
  UseFiltersInstanceProps, UseGlobalFiltersState, UseGlobalFiltersInstanceProps, Row as TableRow
} from 'react-table';

import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form'
import { Row, Col } from 'react-bootstrap';

import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';

import Transaction from '../../core/entities/Transaction';
import Budget from '../../core/entities/Budget';


export type TableInstanceWithHooks<T extends object> = TableInstance<T> &
  UseFiltersInstanceProps<T> &
  UseGlobalFiltersInstanceProps<T> &
  UsePaginationInstanceProps<T> &
  UseSortByInstanceProps<T> & {
    state: UsePaginationState<T> & UseGlobalFiltersState<T>;
  };


type BudgetDropdownProps = {
  selectedBudget: string | undefined;
  setSelectedBudget: (budget: string | undefined) => void;
  options: string[];
}


function BudgetsDropdown({selectedBudget, setSelectedBudget, options}: BudgetDropdownProps) {
  return (
    <select
      className="form-select form-select"
      value={selectedBudget}
      onChange={e => {
        setSelectedBudget(e.target.value || undefined);
      }}
      onClick={e => e.stopPropagation()}
    >
      <option value="">All budgets</option>
      {options.map(option => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

type ShowLastDropdownProps = {
  selected: string | undefined;
  setSelected: (option: string | undefined) => void;
}

function ShowLastDropdown({selected, setSelected}: ShowLastDropdownProps) {
  return (
    <select
      className="form-select form-select"
      value={selected}
      onChange={e => {
        setSelected(e.target.value || undefined);
      }}
      onClick={e => e.stopPropagation()}
    >
      <option value="past7d">Past 7 days</option>
      <option value="past30d">Past 30 days</option>
      <option value="">All</option>
    </select>
  );
}

type ComponentProps = {
  transactions: Transaction[];
  budgetsById: { [index: string]: Budget };
  handleEditTransaction: (transaction: Transaction) => void;
}


export default function TransactionsTable({ transactions, budgetsById, handleEditTransaction }: ComponentProps) {
  const [selectedBudget, setSelectedBudget] = useState<string | undefined>('');
  const [relativeDateFilter, setRelativeDateFilter] = useState<string | undefined>('past30d');
  const [showPlanned, setShowPlanned] = useState<boolean>(true);

  const data = useMemo(() => { 
    return transactions.map(transaction => ({
      id: transaction.id,
      createdAt: transaction.isPlanned ? 'planned' : transaction.createdAt!.toLocaleString(),
      datetime: transaction.createdAt,
      name: transaction.name,
      amount: transaction.amount,
      planned: transaction.isPlanned,
      budgetName: transaction.budgetId && budgetsById[transaction.budgetId]?.name,
      date: Moment(transaction.createdAt!).format('YYYY-MM-DD'),
    }));
  }, [transactions, budgetsById]);

  const budgetOptions: any = useMemo(() => {
    const uniqueValues = new Set(data.filter(row => row.budgetName).map(row => row.budgetName));
    return [...uniqueValues.values()];
  }, [data]);

  const columns: any = useMemo(() => [
    { Header: 'Date', accessor: 'createdAt',
      sortType: (rowA: any, rowB: any, columnId: string, desc: boolean) => {
        if (rowA.values.planned) {
          return 1;
        }
        if (rowB.values.planned) {
          return -1;
        }
        const a = rowA.original['datetime']
        const b = rowB.original['datetime']
        return a > b ? 1 : a < b ? -1 : 0
      },
      filter: (rows: any[], id: string, filterValue: any): any[] => {
        let days = 0;
        if (filterValue === 'past7d') {
          days = 7;
        } else if (filterValue === 'past30d') {
          days = 30;
        }
        return rows.filter(row => {
          const from = new Date(Date.now() - days * 24 * 60 * 60 * 1000);
          const rowValue = row.original.datetime;
          return rowValue >= from;
        })
      }
    },
    { Header: 'Name', accessor: 'name' },
    { Header: 'Planned', accessor: 'planned', filter: 'equals' },
    { Header: 'Amount', accessor: 'amount', sortType: 'basic', sortDescFirst: true,
      Footer: (info: any) => {
        const total = useMemo(
          () =>
            info.rows.reduce((sum: number, row: any) => row.values.amount + sum, 0),
          [info.rows]
        )

        return <><b>{total}</b></>
      },
    },
    { Header: 'Budget', accessor: 'budgetName',
      filter: (rows: TableRow[], id: string, filterValue: any): TableRow[] => {
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue === filterValue;
      });
      }
    },
  ], []);

  const tableInstance = useTable(
    { 
      columns,
      data,
      initialState: {
        // sortBy: useMemo(() => [{ id: 'createdAt', desc: true }], []),
        filters: useMemo(() => [{ id: 'createdAt', value: 'past30d' }], []),
        hiddenColumns: ['planned']
      } as any
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
  ) as TableInstanceWithHooks<any>;

  const { 
    getTableProps, getTableBodyProps,
    headerGroups, rows, prepareRow, footerGroups,
    setFilter, state, setGlobalFilter
  } = tableInstance;

  const { globalFilter } = state;

  const handleSelectBudget = (budget: string | undefined) => {
    setSelectedBudget(budget);
    setFilter("budgetName", budget);
  }

  const handleSetRelativeDateFilter = (option: string | undefined) => {    
    setRelativeDateFilter(option);
    setFilter("createdAt", option);
  }

  const handleShowPlanned = () => {
    setShowPlanned(!showPlanned);
    setFilter("planned", !showPlanned ? undefined : false);
  }

  const openEditTransaction = (id: string) => {
    const transaction = transactions.find(transaction => transaction.id == id);
    if (transaction){
      handleEditTransaction(transaction);
    }
  }

  return (
    <div className="transactions-table">
      <Row className='mt-3 mx-0'>
        <Col sm={8} xs={12}>
          <Form.Group as={Row} controlId="formBasicEmail">
            <Form.Label column sm={2} xs={2}>Search</Form.Label>
            <Col sm={10} xs={10}>
              <Form.Control size='sm' type="text" placeholder="query..." value={globalFilter || ''} onChange={(e) => setGlobalFilter(e.target.value)} />
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Row className='mb-3 mx-0'>
        <Col sm={4} xs={12} className="g-2">
          <BudgetsDropdown selectedBudget={selectedBudget} setSelectedBudget={handleSelectBudget} options={budgetOptions} />
            </Col>
        <Col sm={4} xs={12} className="g-2">
          <ShowLastDropdown selected={relativeDateFilter} setSelected={handleSetRelativeDateFilter} />
            </Col>
        <Col sm={4} xs={12} className="g-2 text-start">
          <div className='d-inline-block ms-2 table-filter-pill'>
            <Form.Check 
              required
              type='switch'
              id='checkbox-total'
              label='Show planned'
              onChange={handleShowPlanned} checked={showPlanned}
            />
          </div>
        </Col>
      </Row>
  
      <Table className="text-start" striped bordered hover size="sm" {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
  
                  {column.isSorted ? (
                  column.isSortedDesc ? (
                    <span><CaretDownFill /></span>
                  ) : (
                    <span><CaretUpFill /></span>
                  )
                ) : null}                  
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()} onClick={() => openEditTransaction(row.original.id)}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
        <tfoot>
        {footerGroups.map(group => (
          <tr {...group.getFooterGroupProps()}>
            {group.headers.map(column => (
              <td {...column.getFooterProps()}>{column.render('Footer')}</td>
            ))}
          </tr>
        ))}
      </tfoot>
      </Table>
    </div>
  );
}