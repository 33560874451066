import { makeAutoObservable } from 'mobx';

import Budget from '../../core/entities/Budget'
import { IBudgetItem } from '../../core/entities/BudgetItem';
import Income from '../../core/entities/Income';
import IncomeAllocation from '../../core/entities/IncomeAllocation';
import Transaction from '../../core/entities/Transaction';
import FirestoreBudgetRepository from '../../core/infrastructure/FirestoreBudgetRepository';
import { TransactionRepository } from '../../core/infrastructure/FirestoreTransactionsRepository';
import { IncomeRepository } from '../../core/infrastructure/FirestoreIncomeRepository';
import { IncomeAllocationRepository } from '../../core/infrastructure/FirestoreIncomeAllocationRepository';
import { createCollection } from '../../core/infrastructure/firebase';
import FullBudget from '../../core/entities/FullBudget';
import FullIncome from '../../core/entities/FullIncome';

import BudgetService from '../../core/usecases/BudgetService';
import AISuggestionsService from '../../core/usecases/AISuggestionsService';

import { RootStore } from './rootStore';


export class BudgetsStore {
    private budgetService: BudgetService;
    private transactionRepository: TransactionRepository;
    private incomeRepository: IncomeRepository;
    private incomeAllocationRepository: IncomeAllocationRepository;

    budgets: Budget[] = [];
    fullBudgets: FullBudget[] = [];

    transactions: Transaction[] = [];
    incomes: Income[] = [];
    fullIncomes: FullIncome[] = [];
    incomeAllocations: IncomeAllocation[] = [];

    budgetsById: {[key: string]: Budget} = {};
    incomesById: {[key: string]: Income} = {};
    incomeAllocationsByBudgetId: {[key: string]: IncomeAllocation[]} = {};
    incomeAllocationsByIncomeId: {[key: string]: IncomeAllocation[]} = {};
    transactionsByBudgetId: {[key: string]: Transaction[]} = {};
    transactionsByIncomeId: {[key: string]: Transaction[]} = {};

    isLoading = false;
    error = '';

    budgetToEdit: Budget | undefined = undefined;
    budgetIdToEdit: string | undefined = undefined;
    incomeToEdit: FullIncome | undefined = undefined;
    transactionToEdit: Transaction | undefined = undefined;
    showBudgetModal = false;
    showIncomeModal = false;
    showFullBudgetModal = false;
    showTransactionModal = false;

    randomize = false;
    lock = false;
    ai = false;

    constructor(protected rootStore: RootStore) {
        makeAutoObservable(this)

        const budgetsCollection = createCollection('budgets');
        const transactionsCollection = createCollection('transactions');
        const incomeCollection = createCollection('income');
        const incomeAllocationCollection = createCollection('income_allocations');

        const budgetRepository = new FirestoreBudgetRepository(budgetsCollection);
        this.transactionRepository = new TransactionRepository(transactionsCollection);
        this.incomeRepository = new IncomeRepository(incomeCollection);
        this.incomeAllocationRepository = new IncomeAllocationRepository(incomeAllocationCollection);
        this.budgetService = new BudgetService(budgetRepository);

        this.randomize = localStorage.getItem('randomize') === 'true'
        this.lock = localStorage.getItem('lock') === 'true'        
        this.ai = localStorage.getItem('ai') === null || localStorage.getItem('ai') === 'true'
    }

    private makeBudgetsObservable(budgets: Budget[])  {
        budgets.forEach(budget =>{
            makeAutoObservable(budget)
            budget.items?.forEach(item => {
                makeAutoObservable(item)
            });
        });
    }

    get userId() {
        return this.rootStore.authStore.userId
    }

    changeRandomize() {        
        this.randomize = !this.randomize
        localStorage.setItem('randomize', this.randomize.toString())
        this.fetchBudgets()
    }

    changeLock() {
        this.lock = !this.lock
        localStorage.setItem('lock', this.lock.toString())
    }

    changeAiSetting() {
        this.ai = !this.ai
        localStorage.setItem('ai', this.ai.toString())
    }

    closeBudgetModal() {
        this.budgetToEdit = undefined;
        this.showBudgetModal = false;
    }

    openBudgetModal(budgetToEdit?: Budget) {
        this.budgetToEdit = budgetToEdit;
        this.showBudgetModal = true;
    };

    closeFullBudgetModal() {
        this.budgetIdToEdit = undefined;
        this.showFullBudgetModal = false;
    }

    openFullBudgetModal(budgetId?: string) {        
        this.budgetIdToEdit = budgetId;
        this.showFullBudgetModal = true;
    };

    closeTransactionModal() {
        this.budgetIdToEdit = undefined;
        this.showTransactionModal = false;
    }
    
    openTransactionModal(budgetId?: string) {
        this.budgetIdToEdit = budgetId;
        this.showTransactionModal = true;
    };

    closeEditTransactionModal() {
        this.transactionToEdit = undefined;
        this.showTransactionModal = false;
    }
    
    openEditTransactionModal(transaction: Transaction) {
        this.transactionToEdit = transaction;
        this.showTransactionModal = true;
    };

    closeIncomeModal() {
        this.incomeToEdit = undefined;
        this.showIncomeModal = false;
    }

    openIncomeModal(incomeToEdit?: FullIncome) {
        this.incomeToEdit = incomeToEdit;
        this.showIncomeModal = true;
    };

    *fetchBudgets() {
        const userId = this.userId;
        if (!userId) {
            this.budgets = []
            return
        }

        this.isLoading = true;

        const budgets: Budget[] = yield this.budgetService.getAll(userId);
        this.makeBudgetsObservable(budgets);

        this.budgets = budgets;
        this.budgetsById = budgets.reduce((acc, budget) => {
            acc[budget.id] = budget
            return acc
        }, {} as {[key: string]: Budget})

        this.transactions = yield this.transactionRepository.getAll(userId)

        this.incomes = yield this.incomeRepository.getAll(userId)
        this.incomeAllocations = yield this.incomeAllocationRepository.getAll(userId)

        if (this.randomize) {
            this.incomes.forEach(income => {
                income.sources.forEach(source => {
                    source.amount = 1000
                })
            })
            this.incomeAllocations.forEach(allocation => {
                allocation.amount = 1000
            })
        }

        this.incomesById = this.incomes.reduce((acc, income) => {
            acc[income.id] = income
            return acc
        }, {} as {[key: string]: Income})

        this.incomeAllocationsByIncomeId = this.incomeAllocations.reduce((acc, incomeAllocation) => {
            if (!acc[incomeAllocation.incomeId]) {
                acc[incomeAllocation.incomeId] = []
            }
            acc[incomeAllocation.incomeId].push(incomeAllocation)
            return acc
        }, {} as {[key: string]: IncomeAllocation[]})

        this.transactionsByIncomeId = this.transactions.reduce((acc, transaction) => {
            if (!transaction.incomeId) {
                return acc
            }
            if (!acc[transaction.incomeId]) {
                acc[transaction.incomeId] = []
            }
            acc[transaction.incomeId].push(transaction)
            return acc
        }, {} as {[key: string]: Transaction[]})

        this.fullIncomes = this.incomes.map(income => {
            return new FullIncome(
                income,
                this.incomeAllocationsByIncomeId[income.id] || [],
                this.transactionsByIncomeId[income.id] || []
            )
        })

        this.incomeAllocationsByBudgetId = this.incomeAllocations.reverse().reduce((acc, incomeAllocation) => {
            if (!acc[incomeAllocation.budgetId]) {
                acc[incomeAllocation.budgetId] = []
            }
            acc[incomeAllocation.budgetId].push(incomeAllocation)
            return acc
        }, {} as {[key: string]: IncomeAllocation[]})
        
        this.transactionsByBudgetId = this.transactions.reduce((acc, transaction) => {
            if (!transaction.budgetId) {
                return acc
            }
            if (!acc[transaction.budgetId]) {
                acc[transaction.budgetId] = []
            }
            acc[transaction.budgetId].push(transaction)
            
            return acc
        }, {} as {[key: string]: Transaction[]})

        this.fullBudgets = budgets.map(budget => {
            return new FullBudget(
                budget,
                this.incomeAllocationsByBudgetId[budget.id],
                this.transactionsByBudgetId[budget.id],
            )
        })

        this.isLoading = false;
    }

    *createBudget(budget: Budget) {
        yield this.budgetService.create(budget, this.userId);
        yield this.fetchBudgets()
    }

    *updateBudget(budget: Budget) {
        yield this.budgetService.update(budget);
        yield this.fetchBudgets()
    }
    
    *deleteBudget(budgetId: string) {
        // delete all transactions
        const transactions = this.transactionsByBudgetId[budgetId]
        if (transactions) {
            for (const transaction of transactions) {
                yield this.transactionRepository.delete(transaction.id)
            }
        }

        yield this.budgetService.delete(budgetId);
        yield this.fetchBudgets()
    }

    *refreshBudget(budget: Budget) {
        yield this.budgetService.refresh(budget);
        yield this.fetchBudgets()
    }

    *collapseBudget(budget: Budget) {
        yield this.budgetService.collapse(budget);
    }

    *addItem(budgetId: string, name: string, amount: number) {
        yield this.budgetService.addItem(budgetId, name, amount);
        yield this.fetchBudgets()
    }

    *addTransaction(tid: string, newTransaction: Transaction) {
        yield this.transactionRepository.create(tid, newTransaction, this.userId)
        yield this.fetchBudgets()
    }

    *updateTransaction(tid: string, newTransaction: Transaction) {
        yield this.transactionRepository.update(tid, newTransaction)
        yield this.fetchBudgets()
    }

    *deleteTransaction(tid: string, skipFetch?: boolean) {
        yield this.transactionRepository.delete(tid)
        if (!skipFetch) {
            yield this.fetchBudgets()
        }
    }
    
    *deleteItem(budgetId: string, itemId: string) {
        yield this.budgetService.deleteItem(budgetId, itemId);
        yield this.fetchBudgets()
    }

    *markItemAsPaid(budgetId: string, item: IBudgetItem, partialAmount?: number) {
        yield this.budgetService.markItemAsPaid(budgetId, item, partialAmount);
        yield this.fetchBudgets()
    }

    *createIncome(income: Income) {
        yield this.incomeRepository.create(income.id, income, this.userId)
        yield this.fetchBudgets()
    }

    *updateIncome(income: Income) {
        yield this.incomeRepository.update(income.id, income)
        yield this.fetchBudgets()
    }

    *deleteIncome(incomeId: string) {
        // delete all income allocations
        const incomeAllocations = this.incomeAllocations.filter(allocation => allocation.incomeId === incomeId)
        for (const allocation of incomeAllocations) {            
            yield this.deleteIncomeAllocation(allocation.id, true)
        }
        // delete all transactions
        const transactions = this.transactions.filter(transaction => transaction.incomeId === incomeId)
        for (const transaction of transactions) {
            yield this.deleteTransaction(transaction.id, true)
        }

        yield this.incomeRepository.delete(incomeId)
        yield this.fetchBudgets()
    }

    *createIncomeAllocation(allocation: IncomeAllocation) {
        yield this.incomeAllocationRepository.create(allocation.id, allocation, this.userId)
        yield this.fetchBudgets()
    }

    *addIncomeAllocationToLatestIncome(allocation: IncomeAllocation) {
        const latestIncome = this.incomes[0]
        allocation.incomeId = latestIncome.id
        yield this.incomeAllocationRepository.create(allocation.id, allocation, this.userId)
        yield this.fetchBudgets()
    }

    *updateIncomeAllocation(allocation: IncomeAllocation) {
        yield this.incomeAllocationRepository.update(allocation.id, allocation)
        yield this.fetchBudgets()
    }

    *deleteIncomeAllocation(allocationId: string, skipFetch?: boolean) {
        yield this.incomeAllocationRepository.delete(allocationId)
        if (!skipFetch) {
            yield this.fetchBudgets()
        }
    }

    async getSuggestionsBySpeech(base64Audio: string, format: string) {
        const response = await new AISuggestionsService().getSuggestionsBySpeech(base64Audio, format, this.publicFullBudgets)
        return response
    }

    async getSuggestions(query: string) {
        return await new AISuggestionsService().getAISuggestions(query, this.publicFullBudgets)
    }   

    get publicBudgets() {
        return this.budgets.filter(budget => !budget.isArchived);
    }

    get publicFullBudgets() {
        return this.fullBudgets.filter(budget => !budget.isArchived);
    }

    get budgetsForTotal() {
        return this.budgets.filter(budget => budget.countInTotal);
    }

    get totalAmountNeeded() {
        return this.budgetsForTotal.reduce((prev, curr) => prev + curr.left, 0)
    }

    getExportData() {
        return {
            fullBudgets: this.fullBudgets,
            incomes: this.incomes
        };
    }
}

export default BudgetsStore
