import { useState } from "react";
import { Mic } from 'react-bootstrap-icons';

type AudioRecorderProps = {
  onDataRecorded: (base64Audio: string, format: string) => void;
};

const AudioRecorder = ({ onDataRecorded }: AudioRecorderProps) => {
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const [recorder, setRecorder] = useState<MediaRecorder | null>(null);

  const handleRecordClick = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setMediaStream(stream);

      let options: any;
      if (MediaRecorder.isTypeSupported('video/webm; codecs=vp9')) {
        options = {mimeType: 'video/webm; codecs=vp9'};
      } else  if (MediaRecorder.isTypeSupported('video/webm')) {
          options = {mimeType: 'video/webm'};
      } else 
      if (MediaRecorder.isTypeSupported('audio/mp4')) {
          options = {mimeType: 'audio/mp4'};
      } else {
          console.error("no suitable mimetype found for this device");
          alert("mic recording not supported on this device");
      }
      const newRecorder = new MediaRecorder(stream, options);

      const chunks: Blob[] = [];

      newRecorder.ondataavailable = (event) => {
        chunks.push(event.data);
      };

      newRecorder.onstop = () => {
        const format = options.mimeType.split(";")[0].split("/")[1];
        const audioBlob = new Blob(chunks, { type: "audio/webm" });
        
        const reader = new FileReader();
        reader.onload = () => {
          const base64Audio = reader.result?.toString().split(",")[1];
          onDataRecorded(base64Audio!, format);
        };
        reader.readAsDataURL(audioBlob);
      };

      newRecorder.start();
      setRecorder(newRecorder);
    } catch (error) {
      console.error("Error recording audio:", error);
    }
  };

  const handleStopClick = () => {
    if (recorder) {
      recorder.stop();
      setRecorder(null);
      mediaStream?.getTracks().forEach((track) => track.stop());
      setMediaStream(null);
    }
  };

  if (recorder) {
    return <button className="btn btn-danger" onClick={handleStopClick}>Stop</button>
  }

  return <button className="btn btn-primary" onClick={handleRecordClick}>
    Ask AI
    <Mic className="cursor-pointer" size={15} />
  </button>
};

export default AudioRecorder;