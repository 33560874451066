
import { observer } from "mobx-react";

import { ReactElement } from 'react'
import Container from 'react-bootstrap/Container';


import Import from "../components/import";

import { useStores } from '../stores/rootStore'


function ImportPage(): ReactElement {
  const rootStore = useStores();
  const budgetsStore = rootStore.budgetsStore;

  return (
    <Container fluid="lg">
      <h2>Import</h2>
      <Import 
        budgetsById={budgetsStore.budgetsById}
        budgetsStore={budgetsStore}
      />
    </Container>
  )
}

export default observer(ImportPage);
