import { v4 as uuidv4 } from 'uuid';

import { ReactElement, useState, ChangeEvent } from 'react'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import BudgetsStore from '../../stores/budgetsStore'
import { TransactionDraft } from '../../../core/entities/Transaction';


import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { Trash } from 'react-bootstrap-icons';
import Transaction from '../../../core/entities/Transaction';
import { SuggestionsResponse } from '../../../core/usecases/AISuggestionsService';


type CreateModalProps = {
  budgetsStore: BudgetsStore,
  show: boolean,
  handleClose: () => void,
  aiResponse: SuggestionsResponse
}

export default function CreateBudgetModal({budgetsStore, show, handleClose, aiResponse}: CreateModalProps): ReactElement {
  let budgetId = aiResponse.newTransactions && aiResponse.newTransactions[0]?.budgetId;
  const [expences, setExpence] = useState<TransactionDraft[]>(aiResponse.newTransactions || []);

  const handleAddExpence = () => {
    setExpence([...expences, {amount: undefined, name: undefined, type: 1, budgetId: budgetId}])
  }

  const handleChangeName = (index: number, event: ChangeEvent<any>): void => {
    const newExpences = [...expences];
    newExpences[index].name = event.target.value;
    setExpence(newExpences);
  }

  const handleChangeAmount = (index: number, event: ChangeEvent<any>): void => {
    const newExpences = [...expences];
    newExpences[index].amount = parseInt(event.target.value);
    setExpence(newExpences);
  }

  const handleRemoveExpence = (index: number) => {
    const newExpences = [...expences];
    newExpences.splice(index, 1);
    setExpence(newExpences);
  }

  const handleSubmit = () => {
    expences.forEach(expence => {
      if (!expence.amount || !expence.name) {
        alert('form is not valid');
        return
      }
      
      const newTransaction = new Transaction({
        id: uuidv4(),
        name: expence.name,
        amount: expence.amount,
        type: expence.type,
        budgetId: budgetId
      });
      budgetsStore.addTransaction(newTransaction.id, newTransaction);
    })
    handleClose();
  }

  const handleChangeBudgetId = (event: ChangeEvent<any>) => {
    budgetId = event.target.value;
    const newExpences = [...expences];
    newExpences.forEach(expence => {
      expence.budgetId = budgetId;
    })
    setExpence(newExpences);
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>AI Suggested Transactions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        AI Response: {aiResponse.message}
        {aiResponse.transcription && <div>AI Speech transcription: {aiResponse.transcription}</div>}
        
        <Form>
          <InputGroup className="mb-3">
            <InputGroup.Text>Budget</InputGroup.Text>
            <Form.Select value={budgetId} onChange={handleChangeBudgetId}>
              <option>-</option>
              {budgetsStore.budgets.map(budget => {
                return <option key={budget.id} value={budget.id}>{budget.name}</option>
              })}
            </Form.Select>
          </InputGroup>
          
          <h5>Expense{expences.length > 1 && 's'}</h5>

          {expences.map((expence, index) => {
            return (
              <Form.Group className="mb-2" key={index}>
                <InputGroup>
                  {/* <InputGroup.Text>Expence</InputGroup.Text> */}
                  <Form.Control autoFocus type="number" placeholder="amount" value={expence.amount || ''} onChange={(event) => handleChangeAmount(index, event)} />
                  <Form.Control name="name" autoComplete='off' placeholder="name" type="text" value={expence.name || ''} onChange={(event) => handleChangeName(index, event)} />
                  <Button disabled={expences.length < 2} variant="outline-danger" size="sm" onClick={() => handleRemoveExpence(index)}><Trash /></Button>
                </InputGroup>
              </Form.Group>
            )
          })}

          <Button className="mt-2" variant="outline-primary" onClick={handleAddExpence}>
            + Expence
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}