import { Timestamp, orderBy } from "firebase/firestore";

import Note from '../entities/Note';
import { BaseFirestoreRepository } from "./baseFirestoreRepository";


export class NotesRepository extends BaseFirestoreRepository<Note>{
  orderBy = orderBy("order", "desc");

  toFirestore(item: Note) {
    return {...item, createdAt: Timestamp.fromDate(item.createdAt)}
  }

  fromFirestore(obj: any): Note {
    return new Note({
      id: obj.id!,
      title: obj.title,
      text: obj.text,
      createdAt: obj.createdAt!.toDate(),
      order: obj.order
    })
  }
}
