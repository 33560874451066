
import { makeAutoObservable } from 'mobx';

import { createCollection } from '../../core/infrastructure/firebase';

import { RootStore } from './rootStore';

import Note from '../../core/entities/Note';
import {NotesRepository} from '../../core/infrastructure/FirestoreNotesRepository';


export default class NotesStore {
    notesRepository: NotesRepository;

    notes: Note[] = [];

    constructor(protected rootStore: RootStore) {
        makeAutoObservable(this);

        const notesCollection = createCollection('notes');

        this.notesRepository = new NotesRepository(notesCollection);
    }

    get userId() {
        return this.rootStore.authStore.userId
    }

    *fetchNotes() {
        this.notes = yield this.notesRepository.getAll(this.userId!);
    }

    *addNote(note: Note) {
        yield this.notesRepository.create(note.id, note, this.userId!);
        this.notes.unshift(note);
    }

    *updateNote(note: Note) {
        yield this.notesRepository.update(note.id, note);
    }

    *deleteNote(id: string) {
        yield this.notesRepository.delete(id);
        this.notes = this.notes.filter(note => note.id !== id);
    }

    *changeOrderUp(note: Note) {
        note.order = note.order! + 1;
        yield this.notesRepository.update(note.id, note);
        
        const noteToSwap = this.notes.find(n => n.order === note.order);
        if (noteToSwap) {
            noteToSwap.order = noteToSwap.order! - 1;
            yield this.notesRepository.update(noteToSwap.id, noteToSwap);
        }
        
        this.notes.sort((a, b) => b.order! - a.order!);
    }
}
