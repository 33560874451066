import { v4 as uuidv4 } from 'uuid';

import React, { ReactElement, useState, ChangeEvent } from 'react'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import BudgetsStore from '../../stores/budgetsStore'


import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { Trash } from 'react-bootstrap-icons';
import Transaction, {TransactionDraft} from '../../../core/entities/Transaction';


type CreateModalProps = {
  budgetsStore: BudgetsStore,
  show: boolean,
  handleClose: () => void,
  budgetId?: string,
  transaction?: Transaction,
}


export default function CreateBudgetModal({budgetsStore, show, handleClose, budgetId, transaction}: CreateModalProps): ReactElement {
  const isEdit = !!transaction;

  const initialName = transaction ? transaction.name : undefined;
  const initialAmount = transaction ? transaction.amount : undefined;
  const initialBudgetId = transaction ? transaction.budgetId! : undefined;
  const initialNote = transaction ? transaction.note! : undefined;


  const [valid, setValid] = useState(false);
  const [expences, setExpence] = useState<TransactionDraft[]>(
    [
      { amount: initialAmount, name: initialName, type: 1, budgetId: initialBudgetId, note: initialNote},
    ]
  );

  const handleAddExpence = (type: number) => {
    setExpence([...expences, {amount: undefined, name: undefined,  type: type, budgetId: budgetId}])
  }

  const handleChangeName = (index: number, event: ChangeEvent<any>): void => {
    const newExpences = [...expences];
    newExpences[index].name = event.target.value;
    setExpence(newExpences);
    isValid();
  }

  const handleChangeNote = (index: number, event: ChangeEvent<any>): void => {
    const newExpences = [...expences];
    newExpences[index].note = event.target.value;
    setExpence(newExpences);
    isValid();
  }

  const handleChangeAmount = (index: number, event: ChangeEvent<any>): void => {
    const newExpences = [...expences];
    newExpences[index].amount = parseInt(event.target.value);
    setExpence(newExpences);
    isValid();
  }

  const handleChangeBudget = (index: number, budgetId: string): void => {
    const newExpences = [...expences];
    newExpences[index].budgetId = budgetId;
    setExpence(newExpences);
    isValid();
  }

  const handleRemoveExpence = (index: number) => {
    const newExpences = [...expences];
    newExpences.splice(index, 1);
    setExpence(newExpences);
  }

  const isValid = () => {
    if (expences.some(expence => expence.amount)) {
      setValid(true);
      return true;
    }

    setValid(false);
    return false;
  }

  const handleSubmit = () => {
    if (isEdit) {
      const updatedTransaction = new Transaction({
        id: transaction.id,
        createdAt: transaction.createdAt,
        name: expences[0].name!,
        amount: expences[0].amount!,
        type: expences[0].type,
        budgetId: expences[0].budgetId,
        note: expences[0].note || '',
        incomeId: transaction.incomeId,
      });
      budgetsStore.updateTransaction(transaction.id, updatedTransaction);
    } else {
      expences.forEach(expence => {
        if (!expence.amount) {
          return;
        }
        
        const newTransaction = new Transaction({
          id: uuidv4(),
          name: expence.name || '',
          amount: expence.amount,
          type: expence.type,
          budgetId: budgetId,
          note: expence.note || '',
        });
        budgetsStore.addTransaction(newTransaction.id, newTransaction);
      })
    }
    handleClose();
  }

  const handleRemoveTransaction = () => {
    budgetsStore.deleteTransaction(transaction!.id);
    handleClose();
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Transaction</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <InputGroup className="mb-3">
            <InputGroup.Text>Budget</InputGroup.Text>
            <Form.Select disabled={!!budgetId && !isEdit} value={isEdit ? expences[0].budgetId : budgetId} onChange={e => handleChangeBudget(0, e.target.value)}>
              <option>-</option>
              {budgetsStore.budgets.map(budget => {
                return <option key={budget.id} value={budget.id}>{budget.name}</option>
              })}
            </Form.Select>
          </InputGroup>
          
          <h5>Expense{expences.length > 1 && 's'}</h5>

          {expences.map((expence, index) => {
            return (
              <div key={index}>
                <Form.Group className="mb-2">
                  <InputGroup>
                    {/* <InputGroup.Text>Expence</InputGroup.Text> */}
                    <Form.Control autoFocus type="number" placeholder="amount" value={expence.amount || ''} onChange={(event) => handleChangeAmount(index, event)} />
                    <Form.Control name="name" autoComplete='off' placeholder="name" type="text" value={expence.name || ''} onChange={(event) => handleChangeName(index, event)} />
                    {!isEdit &&
                      <Button disabled={expences.length < 2} variant="outline-danger" size="sm" onClick={() => handleRemoveExpence(index)}><Trash /></Button>
                    }
                  </InputGroup>
                </Form.Group>
                <div className='mb-3'>
                  <Form.Control
                    type="text"
                    size='sm'
                    placeholder="note"
                    onChange={(event) => handleChangeNote(index, event)}
                    value={expence.note || ''}
                  />
                </div>
              </div>
            )
          })}

          {!isEdit &&
            <Button className="mt-2" variant="outline-primary" onClick={() => handleAddExpence(1)}>
              + Expence
            </Button>
          }
        </Form>
      </Modal.Body>
      <Modal.Footer>
      {isEdit &&
        <Button variant="warning" className="me-auto float-left" onClick={handleRemoveTransaction}>
           Delete
        </Button>
      }
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={!valid}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}