import { useMemo, useState } from 'react'

import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { Trash, CheckCircle, ThreeDotsVertical } from 'react-bootstrap-icons';
import Dropdown from 'react-bootstrap/Dropdown';


import Transaction from '../../core/entities/Transaction';
import styles from './modals/budgetModalFull.module.css';

type ComponentProps = {
  transactions: Transaction[];
  showAll?: boolean;
  handleTransactionToPaid: (transaction: Transaction) => void;
  handleEditTransaction: (transaction: Transaction) => void;
  handleRemoveTransaction: (transactionId: string) => void;
}

type showTransactions = "last7d" | "last30d" | "all";

function getShowMoreLabel(showTransactions: showTransactions) {
  switch (showTransactions) {
    case "last7d":
      return "Show last 30d";
    case "last30d":
      return "Show all";
    case "all":
      return "Hide";
  }
}

export default function TransactionsModalTable({
  transactions, showAll, handleTransactionToPaid, handleEditTransaction, handleRemoveTransaction
}: ComponentProps) {

  const initialShowTransactions = useMemo(() => {
    let value: showTransactions = "last7d";
    const totalTransactions = transactions.length;
    if (totalTransactions < 9) {
      value = "all";
    } else {
      const date30dAgo = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
      const last30dTransactions = transactions.filter(item => item.createdAt >= date30dAgo);
      if (last30dTransactions.length < 9) {
        value = "last30d";
      }
    }
    return value;
  }, [transactions]);


  const [showTransactions, setShowTransactions] = useState<showTransactions>(initialShowTransactions);

  const filteredTransactions = useMemo(() => {
    if (showAll) {
      return transactions;
    } else {
      if (showTransactions === "all") {
        return transactions;
      } else if (showTransactions === "last30d") {
        const from = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
        return transactions.filter(item => item.createdAt >= from);;
      } else {
        const from = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
        return transactions.filter(item => item.createdAt >= from);;
      }
    }
  }, [transactions, showTransactions, showAll]);

  const totalAmount = useMemo(() => {
    return filteredTransactions.reduce((acc, transaction) => acc + transaction.amount, 0);
  }, [filteredTransactions]);

  function handleShowMore() {
    if (showTransactions === "last7d") {
      setShowTransactions("last30d");
    } else if (showTransactions === "last30d") {
      setShowTransactions("all");
    } else {
      setShowTransactions("last7d");
    }
  }

  const startEditTransaction = (event: any, transaction: Transaction) => {
    event.preventDefault();
    event.stopPropagation();
    handleEditTransaction(transaction);
  }

  return (
    <table className="table">
      <thead>
        <tr>
          <th>Date</th>
          <th>Name</th>
          <th className="text-end">Amount</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {filteredTransactions?.map((transaction, index) => (
          <tr key={transaction.id ? transaction.id : index} className="align-middle">
            <td>{transaction.isRegular ? transaction.createdAt.toLocaleString() : 'planned'}</td>
            <td>
              {transaction.name}
              <br />
              <small className='text-secondary'>{transaction.note}</small>
            </td>
            <td className="text-end">
              {transaction.amount}
            </td>
            <td className="align-middle">
              {transaction.isPlanned ? ( 
                <ButtonGroup aria-label="Basic example">
                  <Button
                    variant="outline-success"
                    size="sm"
                    onClick={() => handleTransactionToPaid(transaction)}>
                      <CheckCircle />
                  </Button>
                  <Button
                    variant="outline-danger"
                    size="sm"
                    onClick={() => handleRemoveTransaction(transaction.id)}>
                      <Trash />
                  </Button>
                </ButtonGroup>
              ) : (
                <Dropdown drop='start'>
                  <Dropdown.Toggle className="dots-menu" id="dropdown-basic" as='a'>
                    <ThreeDotsVertical size={18} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={(event) => startEditTransaction(event, transaction)}>
                      Edit
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleRemoveTransaction(transaction.id)}>
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr className={styles['summary-line']}>
          <td colSpan={2}>
            {filteredTransactions.length < transactions.length &&
              <div className="cursor-pointer text-primary" onClick={() => handleShowMore()}>
                <u>{getShowMoreLabel(showTransactions)}</u>
              </div>
            }
          </td>
          <td className='text-end'><strong>{totalAmount}</strong></td>
          <td></td>
        </tr>
      </tfoot>
    </table>
  )
}