import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { useStores } from '../stores/rootStore'


const BasicExample = observer(function() {
  const { authStore, budgetsStore } = useStores();

  const handleExport = () => {
      // Get data to export
      const data = budgetsStore.getExportData();

      // Convert data to JSON string
      const jsonData = JSON.stringify(data, null, 2);

      // Create a blob and download the file
      const blob = new Blob([jsonData], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "budgets_export.json";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  };

  return (
    <>
    <Navbar bg="dark" variant="dark" expand="md" className='mb-3'>
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img
            alt=""
            src="/logo.png"
            width="30"
            height="30"
            className="d-inline-block align-top"
          />{' '}
          Finance.log
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {/* <Nav.Link as={Link} to="/">Home</Nav.Link> */}
            <Nav.Link as={Link} to="/budgets">Budgets</Nav.Link>
            <Nav.Link as={Link} to="/transactions">Transactions</Nav.Link>
            <Nav.Link as={Link} to="/planning">Planning</Nav.Link>
          </Nav>
          <Nav className="justify-content-end">

            {!authStore.loggedIn && <Nav.Link onClick={authStore.signInWithGoogle}>Sign in</Nav.Link>}
            
            {authStore.loggedIn &&
            <NavDropdown title={authStore.user?.email} id="user-dropdown">
                <NavDropdown.Item as={Link} to="/settings">
                  Settings
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/import">
                  Import
                </NavDropdown.Item>
                <NavDropdown.Item onClick={handleExport}>
                  Export
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => budgetsStore.changeAiSetting() }>
                  Toggle AI
                </NavDropdown.Item>
                <NavDropdown.Item>
                  Toggle Demo mode
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => budgetsStore.changeRandomize() }>
                  Hide numbers
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => budgetsStore.changeLock() }>
                  Lock screen
                </NavDropdown.Item>
                <NavDropdown.Item onClick={authStore.signOut}>
                  Sign Out
                </NavDropdown.Item>
            </NavDropdown>
            }
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>

    <div className="float-end">
      {budgetsStore.isLoading && <div className="loader">Loading...</div>}
    </div>
    </>
  );
})

export default BasicExample;