import React from 'react';

import { observer } from "mobx-react";
import {Link} from 'react-router-dom';
import Button from 'react-bootstrap/Button'
import { Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import InputGroup from 'react-bootstrap/InputGroup'
import { PlusSquareFill } from 'react-bootstrap-icons';
import { useState } from 'react';
import AudioRecorder from '../components/audioRecorder';
import AISuggestionsModal from '../components/modals/aiSuggestionsModal';
import { SuggestionsResponse } from '../../core/usecases/AISuggestionsService';

import { useStores } from '../stores/rootStore'

function Loader() {
  return (
    <Spinner animation="border" role="status" size="sm">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
}

// GENERATED BY CHAT GPT!

const App: React.FC = () => {
	const rootStore = useStores();
	const budgetsStore = rootStore.budgetsStore;

  const [query, setQuery] = useState('');

  const [aiLoading, setAiLoading] = useState(false);
  const [aiResponse, setAiResponse] = useState<SuggestionsResponse | undefined>(undefined);

  const [showAIResultsModal, setShowModal] = useState(false);

  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {    
    setQuery(e.target.value);
  }

  const handleEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAskAi();
    }
  }

  const handleAskAi = async () => {
    if (!query) return;
    setAiLoading(true);
    const response = await budgetsStore.getSuggestions(query);
    setAiLoading(false);
    setQuery('');
    setAiResponse(response);
    setShowModal(true);
  }

  const handleAudioRecorded = async (base64Audio: string, format: string) => {
    setAiLoading(true);
    const response = await budgetsStore.getSuggestionsBySpeech(base64Audio, format);
    setAiLoading(false);
    setAiResponse(response);
    setShowModal(true);
  }

  const handleOpenTransactionModal = (event: any, budgetId: string) => {
    event.preventDefault();
    event.stopPropagation();
    budgetsStore.openTransactionModal(budgetId);
  }

  return (
    <div className="container mt-3">

      {showAIResultsModal && <AISuggestionsModal
        budgetsStore={budgetsStore}
        show={showAIResultsModal}
        handleClose={() => setShowModal(false)}
        aiResponse={aiResponse!}
      />}
      
      {budgetsStore.ai &&
        <Row className='my-4'>
          <Col></Col>
          <Col sm={12} md={11}>
            <InputGroup>
              <InputGroup.Text id="basic-addon1">Chat GPT</InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Ask AI to add expence to some budget"
                onChange={handleQueryChange}
                onKeyDown={handleEnterPress}
                value={query}
              />   
              <Button variant='secondary' onClick={handleAskAi}>{aiLoading? <Loader /> : 'Send text'}</Button>
              <AudioRecorder onDataRecorded={handleAudioRecorded} />
            </InputGroup>
          </Col>
          <Col></Col>
        </Row>
      }

      <div className="row">
        <div className="col-md-4">
          <div className='d-flex justify-content-center align-items-center'>
            <h1 className="heading">Income</h1>
            <Button variant="primary" size='sm' className="ms-3" onClick={() => budgetsStore.openIncomeModal()}>Add</Button> 
          </div>
          <Table hover>
            <thead>
              <tr>
                <th className="text-start">Name</th>
                <th className="text-end">Amount/Free</th>
                {/* <th className="text-end">Free</th> */}
              </tr>
            </thead>
            <tbody>
              {budgetsStore.fullIncomes.map((item, index) => (
                <tr key={index} className="cursor-pointer" onClick={() => budgetsStore.openIncomeModal(item)}>
                  <td
                    className="text-start" 
                    
                  >
                      <span className="light-underline">{item.name}</span>
                  </td>
                  <td className="text-end">{item.amount}{item.free ? '/' + item.free: null}</td>
                  {/* <td className="text-end">{item.free}</td> */}
                  {/* <td className="text-end">{(item.amount || 0) - (allocationsByIncomeId[item.id] ? allocationsByIncomeId[item.id].reduce((acc, item) => acc + (item.amount || 0), 0) : 0)}</td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className="col-md-1"></div>
        <div className="col-md-7">
          <div className='d-flex justify-content-center align-items-center'>
            <h1 className="heading">
              <Link to="/budgets" className='text-black'>
                Budgets
              </Link>
            </h1>
            <Button variant="primary" className='ms-4' size='sm' onClick={() => budgetsStore.openFullBudgetModal()}>Add</Button>
          </div>
          <Table hover>
            <thead>
              <tr>
                <th className="text-start">Name</th>
                <th className="text-end">Balance</th>
                <th className="text-end">Spent 30d</th>
                {/* <th>Remaining</th> */}
              </tr>
            </thead>
            <tbody>
              {budgetsStore.fullBudgets.filter(budget => !budget.isArchived).map(item => (
                <tr key={item.id} className="cursor-pointer" onClick={() => budgetsStore.openFullBudgetModal(item.id)}>
                  <td
                    className="text-start"
                    
                  >
                    <span className="light-underline">{item.name}</span>
                  </td>
                  <td className="text-end">{item.left}</td>
                  <td className="text-end">{item.spent30d}{' '}
                    <PlusSquareFill className="cursor-pointer text-primary" size={18} onClick={(event) => handleOpenTransactionModal(event, item.id)} />
                  </td>
                  {/* <td>{item.amount}</td> */}
                </tr>
              ))}
              <tr>
                <td className="text-start">Total</td> 
                <td className="text-end"><strong>{budgetsStore.fullBudgets.filter(budget => !budget.isArchived && budget.countInTotal).reduce((acc, item) => acc + item.left, 0)}</strong></td>
                <td className="text-end"><strong>{budgetsStore.fullBudgets.filter(budget => !budget.isArchived && budget.countInTotal).reduce((acc, item) => acc + item.spent30d, 0)}</strong></td>
                <td></td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default observer(App);
